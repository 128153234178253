
import { Component, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MerchOperationService } from 'src/app/_services/merch-operation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-merch-govern',
  templateUrl: './merch-govern.component.html',
  styleUrls: ['./merch-govern.component.scss']
})
export class MerchGovernComponent implements OnInit {

  Governorates: Array<any> = [];
  governorateForm: FormGroup;
  accessToken: string;

  updating: boolean = false;
  governorateIdToUpdate: Number = null;
  regionDropdownSettings: IDropdownSettings;

  @ViewChild(DataTableDirective, {
    static: false,
  })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public regions: Array<any> = [];


  constructor(
    private fb: FormBuilder,
    private operationService: MerchOperationService
  ) { }

  ngOnInit(): void {
    this.accessToken = localStorage.getItem('accessToken');
    this.loadRegions();

    this.governorateForm = this.fb.group({
      name: ['', [Validators.required]],
      name_ar: [''],
      region: ['', [Validators.required]],
      regionshow: ['', [Validators.required]]
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true,
      destroy: true
    }

    this.regionDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.listAllGovernorates();
  }

  ngAfterViewInit(): void {
    if (!this.dtElement.dtInstance) {
      this.dtTrigger.next();
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  
  loadRegions() {
    Swal.showLoading();
    this.operationService.getRegions().subscribe(response => {
      Swal.close();
      if (response.success) {
        this.regions = response.data?.results;
        console.log(this.regions)
        this.rerender();
      } else {
        Swal.fire({
          icon: 'error',
          title: response.error.error_message || "Problem in fetching regions!"
        });
      }
    }, error => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: error.error.error_message || "Problem in fetching regions!"
      });
    })
  }

  listAllGovernorates() {
    Swal.showLoading();
    this.operationService.getGovernorates()
      .subscribe(response => {
        Swal.close();
        if (response.success) {
          this.Governorates = response.data?.results;

          console.log(this.Governorates)
          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: response.error.error_message || "Problem in fetching Governorates!"
          });
        }
      }, error => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: error.error.error_message || "Problem in fetching Governorates!"
        });
      })
  }

  removegovernorate(governorateId) {
    this.operationService.removeGovernorate(governorateId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "governorate deleted successfully!"
        }).then(res => {
          this.listAllGovernorates();
        })
      })
      .catch(error => {
        Swal.fire({
          icon: "error",
          titleText: "Something went wrong while deleting governorate!"
        })
      })
  }

  updategovernorate() {
    if (this.governorateForm.invalid) {
      for (var i in this.governorateForm.controls) {
        this.governorateForm.controls[i].markAsDirty();
        this.governorateForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      if (this.governorateIdToUpdate) {
        this.operationService.updateGovernorate(this.governorateIdToUpdate, this.governorateForm.value)
          .subscribe(
            response => {
              Swal.close();
              if (response.success) {
                Swal.fire({
                  icon: 'success',
                  title: "governorate updated successfully!"
                }).then(() => {
                  this.governorateIdToUpdate = null;
                  this.listAllGovernorates();
                  this.updating = false;
                  this.governorateForm.reset();
                })
              } else {
                Swal.fire({
                  icon: 'error',
                  title: response.error_message || "Problem in updating governorate!"
                });
              }
            },
            error => {
              Swal.close();
              Swal.fire({
                icon: 'error',
                title: error.error.error_message || "Problem in adding governorate!"
              });
            }
          )
      }
    }

  }

  addGovernorate() {
    this.governorateForm.controls['region'].setValue(this.governorateForm.controls['regionshow'].value[0].id)
    if (this.governorateForm.invalid) {
      for (var i in this.governorateForm.controls) {
        this.governorateForm.controls[i].markAsDirty();
        this.governorateForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      this.operationService.addGovernorate(this.governorateForm.value)
        .subscribe(
          response => {
            Swal.close();
            if (response.success) {
              Swal.fire({
                icon: 'success',
                title: "governorate added successfully!"
              }).then(() => {
                this.listAllGovernorates();
                this.updating = false;
                this.governorateForm.reset();
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: response.error_message || "Problem in adding governorate!"
              });
            }
          },
          error => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: error.error.error_message || "Problem in adding governorate!"
            });
          }
        )
    }
  }

  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }
  // helper methods
  fetchgovernorate(governorate) {
    this.updating = true;
    this.governorateIdToUpdate = governorate.id || null;
    this.governorateForm = this.fb.group({
      name: [governorate.name, [Validators.required]],
      name_ar: [governorate.name_ar],
      region: [governorate.region, [Validators.required]],
      regionshow: [[{
        'id': governorate.region,
        'name': governorate.region_name,
      }], [Validators.required]]
    });
  }

  toggleUpdateMode() {
    this.updating = false;
    this.governorateForm.reset();
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

}
