
import { Component, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MerchOperationService } from 'src/app/_services/merch-operation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-merch-regions',
  templateUrl: './merch-regions.component.html',
  styleUrls: ['./merch-regions.component.scss']
})

export class MerchRegionsComponent implements OnInit {


  Regions: Array<any> = [];
  regionForm: FormGroup;
  accessToken: string;

  updating: boolean = false;
  regionIdToUpdate: Number = null;
  countryDropdownSettings: IDropdownSettings;

  @ViewChild(DataTableDirective, {
    static: false,
  })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public countries: Array<any> = [];


  constructor(
    private fb: FormBuilder,
    private operationService: MerchOperationService
  ) { }

  ngOnInit(): void {
    this.accessToken = localStorage.getItem('accessToken');
    this.loadCountries();

    this.regionForm = this.fb.group({
      name: ['', [Validators.required]],
      name_ar: [''],
      country: ['', [Validators.required]],
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true,
      destroy: true
    }

    this.countryDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.listAllRegions();
  }

  ngAfterViewInit(): void {
    if (!this.dtElement.dtInstance) {
      this.dtTrigger.next();
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  loadCountries() {
    Swal.showLoading();
    this.operationService.getCountries().subscribe(response => {
      Swal.close();
      if (response.success) {
        this.countries = response.data?.results;
        console.log(this.countries)
        this.rerender();
      } else {
        Swal.fire({
          icon: 'error',
          title: response.error.error_message || "Problem in fetching countries!"
        });
      }
    }, error => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: error.error.error_message || "Problem in fetching countries!"
      });
    })
  }

  listAllRegions() {
    Swal.showLoading();
    this.operationService.getRegions()
      .subscribe(response => {
        Swal.close();
        if (response.success) {
          this.Regions = response.data?.results;
          console.log(this.Regions)
          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: response.error.error_message || "Problem in fetching Regions!"
          });
        }
      }, error => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: error.error.error_message || "Problem in fetching Regions!"
        });
      })
  }

  removeregion(regionId) {
    this.operationService.removeRegion(regionId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "region deleted successfully!"
        }).then(res => {
          this.listAllRegions();
        })
      })
      .catch(error => {
        Swal.fire({
          icon: "error",
          titleText: "Something went wrong while deleting region!"
        })
      })
  }

  updateregion() {
    if (this.regionForm.invalid) {
      for (var i in this.regionForm.controls) {
        this.regionForm.controls[i].markAsDirty();
        this.regionForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      if (this.regionIdToUpdate) {
        this.operationService.updateRegion(this.regionIdToUpdate, this.regionForm.value)
          .subscribe(
            response => {
              Swal.close();
              if (response.success) {
                Swal.fire({
                  icon: 'success',
                  title: "region updated successfully!"
                }).then(() => {
                  this.regionIdToUpdate = null;
                  this.listAllRegions();
                  this.updating = false;
                  this.regionForm.reset();
                })
              } else {
                Swal.fire({
                  icon: 'error',
                  title: response.error_message || "Problem in updating region!"
                });
              }
            },
            error => {
              Swal.close();
              Swal.fire({
                icon: 'error',
                title: error.error.error_message || "Problem in adding region!"
              });
            }
          )
      }
    }

  }

  importLocations(event) {
    let file = event.target.files[0];
    if (file) {

      Swal.showLoading();
      // check if file has valid extension
      let fileExtension = file.name.split('.')[1];
      if (fileExtension !== 'csv') {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Please upload csv file!'
        });
        return false;
      }

      // check valid file size
      let size = file.size / (1024 * 1024);
      if (size > 4) {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Please only upload file less than 4MB !'
        });
        return false;
      }

      // send data to api
      let csvData = new FormData();
      csvData.append('file', new Blob([file], { type: 'text/csv' }), file.name);
      csvData.append('country_id',"1")
      this.operationService.importLocations(csvData)
        .subscribe(res => {
          Swal.close()
          if (res.success) {
            Swal.fire({
              icon: 'success',
              title: res.data.message
            }).then(() => {
              event.target.value = '';
              this.listAllRegions();
            })
          } else {
            event.target.value = '';
            this.handleNotification(res.error_message || "Something went wrong while importing the file!", true);
          }
        }, err => {
          event.target.value = '';
          Swal.close()
          this.handleNotification(err.error.error_message || "Something went wrong while importing the file!", true);
        });
    }
  }

  importProducts(event) {
    let file = event.target.files[0];
    if (file) {

      Swal.showLoading();
      // check if file has valid extension
      let fileExtension = file.name.split('.')[1];
      if (fileExtension !== 'csv') {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Please upload csv file!'
        });
        return false;
      }

      // check valid file size
      let size = file.size / (1024 * 1024);
      if (size > 4) {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Please only upload file less than 4MB !'
        });
        return false;
      }

      // send data to api
      let csvData = new FormData();
      csvData.append('file', new Blob([file], { type: 'text/csv' }), file.name);
      csvData.append('country_id',"1")
      this.operationService.importProducts(csvData)
        .subscribe(res => {
          Swal.close()
          if (res.success) {
            Swal.fire({
              icon: 'success',
              title: res.data.message
            }).then(() => {
              event.target.value = '';
            })
          } else {
            event.target.value = '';
            this.handleNotification(res.error_message || "Something went wrong while importing the file!", true);
          }
        }, err => {
          event.target.value = '';
          Swal.close()
          this.handleNotification(err.error.error_message || "Something went wrong while importing the file!", true);
        });
    }
  }


  addRegion() {
    if (this.regionForm.invalid) {
      for (var i in this.regionForm.controls) {
        this.regionForm.controls[i].markAsDirty();
        this.regionForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      this.regionForm.controls['country'].setValue(1)
      this.operationService.addRegion(this.regionForm.value)
        .subscribe(
          response => {
            Swal.close();
            if (response.success) {
              Swal.fire({
                icon: 'success',
                title: "region added successfully!"
              }).then(() => {
                this.listAllRegions();
                this.updating = false;
                this.regionForm.reset();
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: response.error_message || "Problem in adding region!"
              });
            }
          },
          error => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: error.error.error_message || "Problem in adding region!"
            });
          }
        )
    }
  }

  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }
  // helper methods
  fetchregion(region) {
    this.updating = true;
    this.regionIdToUpdate = region.id || null;
    this.regionForm = this.fb.group({
      name: [region.name, [Validators.required]],
      name_ar: [region.name_ar],
      country: [this.countries[0].id, [Validators.required]]
    });
  }

  toggleUpdateMode() {
    this.updating = false;
    this.regionForm.reset();
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

}
