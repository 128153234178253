import { SkuComponent } from './operations/sku/sku.component';
import { BrandComponent } from './operations/brand/brand.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SupportComponent } from './support/support.component';
import { TermsComponent } from './terms/terms.component';
import { FieldWorkCreateComponent } from './field-work-create/field-work-create.component';
import { FieldWorkEditComponent } from './field-work-edit/field-work-edit.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { CreateProfileComponent } from './create-profile/create-profile.component';
import { FieldWorkViewComponent } from './field-work-view/field-work-view.component';
import { SingleFieldWorkComponent } from './single-field-work/single-field-work.component';
import { QuestionnairesComponent } from './questionnaires/questionnaires.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { SingleQuestionnaireComponent } from './single-questionnaire/single-questionnaire.component';
import { CreateQuestionnaireComponent } from './create-questionnaire/create-questionnaire.component';
import { OperationsComponent } from './operations/operations.component';
import { PortalUserComponent } from './portal-user/portal-user.component';
import { ChangepasswordComponents } from './change-password/changepassword.component';
import { CreatePortalUserComponent } from './portal-user/create-portal-user.component';
import { AuthGuard } from './_shared/auth.guard';
import { MyAccountComponent } from './my-account/my-account.component';
import { ChannelComponent } from './operations/channel/channel.component';
import { RetailerComponent } from './operations/retailer/retailer.component';
import { BranchComponent } from './operations/branch/branch.component';
import { CategoryComponent } from './operations/category/category.component';
import { QuestionnaireTypeComponent } from './operations/questionnaire-type/questionnaire-type.component';
import { CreateQuestionComponent } from './create-question/create-question.component';
import { OptionQuestionChartComponent } from './dashboard/option-question-chart/option-question-chart.component';
import { PriceMonitorComponent } from './snap-data/price-monitor/price-monitor.component';
import { OutOfStockComponent } from './snap-data/out-of-stock/out-of-stock.component';
import { DistributionCheckComponent } from './snap-data/distribution-check/distribution-check.component';
import { ConsumerCheckComponent } from './snap-data/consumer-check/consumer-check.component';
import { SnapDashboardComponent } from './snap-dashboard/dashboard/dashboard.component';
import { SnapOptionQuestionChartComponent } from './snap-dashboard/dashboard/snap-option-question-chart/snap-option-question-chart.component';
import { SnapOperationsComponent } from './snap-operations/operations.component';
import { SnapChannelComponent } from './snap-operations/channel/channel.component';
import { SnapRetailerComponent } from './snap-operations/retailer/retailer.component';
import { SnapBranchComponent } from './snap-operations/branch/branch.component';
import { SnapBrandComponent } from './snap-operations/brand/brand.component';
import { SnapSkuComponent } from './snap-operations/sku/sku.component';
import { SnapCategoryComponent } from './snap-operations/category/category.component';
import { SnapCityComponent } from './snap-operations/city/city.component';
import { MerchOperationsComponent } from './merch-operations/merch-operations.component';
import { MerchCountryComponent } from './merch-operations/merch-country/merch-country.component';
import { MerchRegionsComponent } from './merch-operations/merch-regions/merch-regions.component';
import { MerchGovernComponent } from './merch-operations/merch-govern/merch-govern.component';
import { MerchStoreNameComponent } from './merch-operations/merch-store-name/merch-store-name.component';
import { MerchStoreTypeComponent } from './merch-operations/merch-store-type/merch-store-type.component';
import { MerchBranchNoComponent } from './merch-operations/merch-branch-no/merch-branch-no.component';
import { MerchBranchNameComponent } from './merch-operations/merch-branch-name/merch-branch-name.component';
import { MerchCategoryComponent } from './merch-operations/merch-category/merch-category.component';
import { MerchSubCategoryComponent } from './merch-operations/merch-sub-category/merch-sub-category.component';
import { MerchSkuComponent } from './merch-operations/merch-sku/merch-sku.component';
import { MerchBrandComponent } from './merch-operations/merch-brand/merch-brand.component';
import { DashboardMerchComponent } from './merch-dashboard/dashboard-merch/dashboard-merch.component';
import { MerchCityComponent } from './merch-operations/merch-city/merch-city.component';
import { MerchAuditsComponent } from './merch-operations/merch-audits/merch-audits.component';
import { MerchAgentsComponent } from './merch-operations/merch-agents/merch-agents.component';
import { MerchAuditResponseComponent } from './merch-operations/merch-audit-response/merch-audit-response.component';
import { AuditEditComponent } from './merch-operations/audit-edit/audit-edit.component';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'reset-password',
    component: ForgetpasswordComponent
  },
  {
    path: 'password/reset/:uid/:token',
    component: ChangepasswordComponents
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardMerchComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'snap-dashboard', 
    redirectTo:'/merch-dashboard'
  },
  {
    path: 'merch-dashboard',
    canActivate: [AuthGuard],
    component: DashboardMerchComponent
  },
  {
    path: 'support',
    canActivate: [AuthGuard],
    component: SupportComponent
  },
  {
    path: 'terms',
    canActivate: [AuthGuard],
    component: TermsComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'create-field-work/:id',
    canActivate: [AuthGuard],
    component: FieldWorkCreateComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'create-field-work',
    canActivate: [AuthGuard],
    component: FieldWorkCreateComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'edit-field-work',
    canActivate: [AuthGuard],
    component: FieldWorkEditComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'create-profile',
    canActivate: [AuthGuard],
    component: CreateProfileComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'edit-profile',
    canActivate: [AuthGuard],
    component: EditProfileComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'view-field-work',
    canActivate: [AuthGuard],
    component: FieldWorkViewComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'single-field-work/:id',
    canActivate: [AuthGuard],
    component: SingleFieldWorkComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'view-questionnaire',
    canActivate: [AuthGuard],
    component: QuestionnairesComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'view-single-questionnaire/:questionnaireId',
    canActivate: [AuthGuard],
    component: QuestionnaireComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'single-questionnaire/:responseId',
    canActivate: [AuthGuard],
    component: SingleQuestionnaireComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'create-questionnaire',
    canActivate: [AuthGuard],
    component: CreateQuestionnaireComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'create-questionnaire/:id',
    canActivate: [AuthGuard],
    component: CreateQuestionnaireComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'operations',
    canActivate: [AuthGuard],
    component: OperationsComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'operations/channel',
    canActivate: [AuthGuard],
    component: ChannelComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'operations/retailer',
    canActivate: [AuthGuard],
    component: RetailerComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'operations/branch',
    canActivate: [AuthGuard],
    component: BranchComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'operations/brand',
    canActivate: [AuthGuard],
    component: BrandComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'operations/sku',
    canActivate: [AuthGuard],
    component: SkuComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'operations/category',
    canActivate: [AuthGuard],
    component: CategoryComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'operations/questionnaire-type',
    canActivate: [AuthGuard],
    component: QuestionnaireTypeComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch-operations',
    canActivate: [AuthGuard],
    component: MerchOperationsComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch-operations/country',
    canActivate: [AuthGuard],
    component: MerchCountryComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch-operations/regions',
    canActivate: [AuthGuard],
    component: MerchRegionsComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch-operations/gov',
    canActivate: [AuthGuard],
    component: MerchGovernComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch-operations/city',
    canActivate: [AuthGuard],
    component: MerchCityComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch-operations/storename',
    canActivate: [AuthGuard],
    component: MerchStoreNameComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch-operations/storetype',
    canActivate: [AuthGuard],
    component: MerchStoreTypeComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch-operations/branchno',
    canActivate: [AuthGuard],
    component: MerchBranchNoComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch-operations/branchname',
    canActivate: [AuthGuard],
    component: MerchBranchNameComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch-operations/category',
    canActivate: [AuthGuard],
    component: MerchCategoryComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch-operations/subCategory',
    canActivate: [AuthGuard],
    component: MerchSubCategoryComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch-operations/brand',
    canActivate: [AuthGuard],
    component: MerchBrandComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch-operations/SKU',
    canActivate: [AuthGuard],
    component: MerchSkuComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch/audits',
    canActivate: [AuthGuard],
    component: MerchAuditsComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch/audit-response/:id',
    canActivate: [AuthGuard],
    component: MerchAuditResponseComponent,
    data: {
      role: 'Admin'
    }
  },  {
    path: 'merch/audit-response/edit/:id',
    canActivate: [AuthGuard],
    component: AuditEditComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'merch/users',
    canActivate: [AuthGuard],
    component: MerchAgentsComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'snap-operations',
    canActivate: [AuthGuard],
    component: SnapOperationsComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'snap-operations/city',
    canActivate: [AuthGuard],
    component: SnapCityComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'snap-operations/channel',
    canActivate: [AuthGuard],
    component: SnapChannelComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'snap-operations/retailer',
    canActivate: [AuthGuard],
    component: SnapRetailerComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'snap-operations/branch',
    canActivate: [AuthGuard],
    component: SnapBranchComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'snap-operations/brand',
    canActivate: [AuthGuard],
    component: SnapBrandComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'snap-operations/sku',
    canActivate: [AuthGuard],
    component: SnapSkuComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'snap-operations/category',
    canActivate: [AuthGuard],
    component: SnapCategoryComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'portal-users',
    canActivate: [AuthGuard],
    component: PortalUserComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'create-portal-user',
    canActivate: [AuthGuard],
    component: CreatePortalUserComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'edit-portal-user/:id',
    canActivate: [AuthGuard],
    component: CreatePortalUserComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'my-account',
    canActivate: [AuthGuard],
    component: MyAccountComponent
  },
  {
    path: 'create-question/:id',
    canActivate: [AuthGuard],
    component: CreateQuestionComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'snap-data/price-monitor',
    canActivate: [AuthGuard],
    component: PriceMonitorComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'snap-data/out-of-stock',
    canActivate: [AuthGuard],
    component: OutOfStockComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'snap-data/distribution-check',
    canActivate: [AuthGuard],
    component: DistributionCheckComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'snap-data/consumer-check',
    canActivate: [AuthGuard],
    component: ConsumerCheckComponent,
    data: {
      role: 'Admin'
    }
  },
  {
    path: 'consumer-question/view-option-question-charts',
    canActivate: [AuthGuard],
    component: OptionQuestionChartComponent
  },

  {
    path: 'snap-consumer-question/view-option-question-charts',
    canActivate: [AuthGuard],
    component: SnapOptionQuestionChartComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
