import { Component, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MerchOperationService } from 'src/app/_services/merch-operation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-merch-store-type',
  templateUrl: './merch-store-type.component.html',
  styleUrls: ['./merch-store-type.component.scss']
})
export class MerchStoreTypeComponent implements OnInit {

  Storetypes: Array<any> = [];
  storetypeForm: FormGroup;
  accessToken: string;

  updating: boolean = false;
  storetypeIdToUpdate: Number = null;

  @ViewChild(DataTableDirective, {
    static: false,
  })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  constructor(
    private fb: FormBuilder,
    private operationService: MerchOperationService
  ) { }

  ngOnInit(): void {
    this.accessToken = localStorage.getItem('accessToken');

    this.storetypeForm = this.fb.group({
      name: ['', [Validators.required]],
      name_ar: [''], 
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true,
      destroy: true
    }
    this.listAllStoretypes();
  }

  ngAfterViewInit(): void {
    if (!this.dtElement.dtInstance) {
      this.dtTrigger.next();
    }

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  listAllStoretypes() {
    Swal.showLoading();
    this.operationService.getStoretypes()
      .subscribe(response => {
        Swal.close();
        if (response.success) {
          this.Storetypes = response.data?.results;

          console.log(this.Storetypes)
          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: response.error.error_message || "Problem in fetching Storetypes!"
          });
        }
      }, error => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: error.error.error_message || "Problem in fetching Storetypes!"
        });
      })
  }

  removestoretype(storetypeId) {
    this.operationService.removeStoretype(storetypeId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "storetype deleted successfully!"
        }).then(res => {
          this.listAllStoretypes();
        })
      })
      .catch(error => {
        Swal.fire({
          icon: "error",
          titleText: "Something went wrong while deleting storetype!"
        })
      })
  }

  updatestoretype() {
    if (this.storetypeForm.invalid) {
      for (var i in this.storetypeForm.controls) {
        this.storetypeForm.controls[i].markAsDirty();
        this.storetypeForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      if (this.storetypeIdToUpdate) {
        this.operationService.updateStoretype(this.storetypeIdToUpdate, this.storetypeForm.value)
          .subscribe(
            response => {
              Swal.close();
              if (response.success) {
                Swal.fire({
                  icon: 'success',
                  title: "storetype updated successfully!"
                }).then(() => {
                  this.storetypeIdToUpdate = null;
                  this.listAllStoretypes();
                  this.updating = false;
                  this.storetypeForm.reset();
                })
              } else {
                Swal.fire({
                  icon: 'error',
                  title: response.error_message || "Problem in updating storetype!"
                });
              }
            },
            error => {
              Swal.close();
              Swal.fire({
                icon: 'error',
                title: error.error.error_message || "Problem in adding storetype!"
              });
            }
          )
      }
    }
  }

  addStoretype() { 
    if (this.storetypeForm.invalid) {
      for (var i in this.storetypeForm.controls) {
        this.storetypeForm.controls[i].markAsDirty();
        this.storetypeForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      this.operationService.addStoretype(this.storetypeForm.value)
        .subscribe(
          response => {
            Swal.close();
            if (response.success) {
              Swal.fire({
                icon: 'success',
                title: "storetype added successfully!"
              }).then(() => {
                this.listAllStoretypes();
                this.updating = false;
                this.storetypeForm.reset();
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: response.error_message || "Problem in adding storetype!"
              });
            }
          },
          error => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: error.error.error_message || "Problem in adding storetype!"
            });
          }
        )
    }
  }

  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }

  // helper methods
  fetchstoretype(storetype) {
    this.updating = true;
    this.storetypeIdToUpdate = storetype.id || null;
    this.storetypeForm = this.fb.group({
      name: [storetype.name, [Validators.required]],
      name_ar: [storetype.name_ar],
    });
  }

  toggleUpdateMode() {
    this.updating = false;
    this.storetypeForm.reset();
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

}
