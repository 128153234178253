import { AuthService } from './../_services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-left-side-bar',
  templateUrl: './left-side-bar.component.html',
  styleUrls: ['./left-side-bar.component.scss']
})
export class LeftSideBarComponent implements OnInit {
  fullname: string;
  role: string;
  profileImage: string;

  open_menu = false;
  isAdmin: Boolean = false;

  regions: boolean = false
  gov: boolean = false
  city: boolean = false
  store: boolean = false
  branchno: boolean = false
  branchna: boolean = false
  storetype: boolean = false
  category: boolean = false
  subcategory: boolean = false
  brand: boolean = false
  sku: boolean = false
  users: boolean = false
  audits: boolean = false
  audit_response = false

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    router.events.subscribe((val) => {
      setTimeout(() => {
        this.scroll()
      }, 300);
    })
  }

  ngOnInit(): void {
    this.fullname = localStorage.getItem('fullName');
    this.role = localStorage.getItem('role');
    this.profileImage = localStorage.getItem('avatar');

    const accessToken = localStorage.getItem('accessToken');
    if (this.authService.getCurrentUserRole(accessToken) == "Admin") {
      this.isAdmin = true
    }

    if (this.router.url.includes('regions')) {
      this.open_menu = true;
      this.regions = true;
    }
    else if (this.router.url.includes('gov')) {
      this.open_menu = true;
      this.gov = true;
    }
    else if (this.router.url.includes('city')) {
      this.open_menu = true;
      this.city = true;
    }
    else if (this.router.url.includes('storename')) {
      this.open_menu = true;
      this.store = true;
    } else if (this.router.url.includes('branchname')) {
      this.open_menu = true;
      this.branchna = true;
    } else if (this.router.url.includes('branchno')) {
      this.open_menu = true;
      this.branchno = true;
    } else if (this.router.url.includes('brand')) {
      this.open_menu = true;
      this.brand = true;
    } else if (this.router.url.includes('storetype')) {
      this.open_menu = true;
      this.storetype = true;
    } else if (this.router.url.includes('category')) {
      this.open_menu = true;
      this.category = true;
    } else if (this.router.url.includes('subCategory')) {
      this.open_menu = true;
      this.subcategory = true;
    } else if (this.router.url.includes('SKU')) {
      this.open_menu = true;
      this.sku = true;
    } else if (this.router.url.includes('users')) {
      this.open_menu = true;
      this.users = true;
    } else if (this.router.url.includes('audits')) {
      this.open_menu = true;
      this.audits = true;
    } else if (this.router.url.includes('audit-response')) {
      this.open_menu = true;
      this.audits = true;
    }
  }
  scroll() {
    document.querySelector(".left-side-menu").scrollTo(0, 500)
  }
}
