<div class="navbar-custom" >
    <ul class="list-unstyled topnav-menu float-right mb-0">

        <li class="d-none d-sm-block">
            <form class="app-search">
                <div class="app-search-box">
                    <div class="input-group search">
                        <input id="dtSearchText" 
                        type="text" class="form-control" 
                        [(ngModel)]="filterText" [ngModelOptions]="{standalone: true}" [pattern]="pattern" placeholder="Search...">
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button" (click)="sendSearchText()">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </li>

        <li class="dropdown notification-list" style="position:relative; z-index: 1200;">
            <a class="nav-link dropdown-toggle nav-user mr-0 waves-effect" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                <img src="{{profileImage}}" onerror="this.src='../../assets/images/user_icon.jpg';" alt="user-image" class="rounded-circle">
                <span class="pro-user-name ml-1">
                    {{ firstname }} <i class="mdi mdi-chevron-down"></i>
                </span>
            </a>
            <div class="dropdown-menu dropdown-menu-right profile-dropdown ">
                <!-- item-->
                <div class="dropdown-header noti-title">
                    <h6 class="text-overflow m-0">Welcome !</h6>
                </div>

                <!-- item-->
                <a routerLink="/my-account" class="dropdown-item notify-item">
                    <fa-icon [icon]="faUser"></fa-icon>&nbsp;
                    <span>My Account</span>
                </a>

                <div class="dropdown-divider"></div>

                <!-- item-->
                <a (click)="userLogout()" class="dropdown-item notify-item">
                    <fa-icon [icon]="faSignOutAlt"></fa-icon>&nbsp;
                    <span>Logout</span>
                </a>

            </div>
        </li>

        <li class="dropdown notification-list" *ngIf="isAdmin">
            <a routerLink="/portal-users" class="nav-link right-bar-toggle waves-effect">
                <fa-icon [icon]="faCog"></fa-icon>
            </a>
        </li>


    </ul>

    <!-- LOGO -->
    <div class="logo-box">
        <p class="logo logo-dark text-center mb-0">
            <span class="logo-lg">
                <div class="text-center mt-0">
                    <h4 class="mt-0 font-weight-bold mb-0"><span class="green-color">AIC-</span>Egypt</h4>
                </div>
            </span>
            <span class="logo-sm">
                <div class="text-center mb-4 mt-0">
                    <h4 class="mt-0 font-weight-bold"><span class="sms-color">AIC-</span>Egypt</h4>
                </div>
            </span>
        </p>
        <p class="logo logo-light text-center">
            <span class="logo-lg">
                <!-- <img src="assets/images/logo-light.png" alt="" height="16"> -->
            </span>
            <span class="logo-sm">
                <!-- <img src="assets/images/logo-sm.png" alt="" height="24"> -->
            </span>
        </p>
    </div>

    <ul class="list-unstyled topnav-menu topnav-menu-left mb-0">
        <li>
            <button class="button-menu-mobile disable-btn waves-effect">
                <i class="fe-menu"></i>
            </button>
        </li>

        <li>
            <h4 class="page-title-main">{{pageTitle}}</h4>
        </li>

    </ul>

</div>
