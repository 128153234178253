import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color, Label } from 'ng2-charts';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { DashboardService } from '../_services/dashboard.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

export interface BarData {
  data: Array<Number>,
  label: String
}


@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss']
})
export class BarchartComponent implements OnInit {
  faEllipsisV = faEllipsisV;

  @Input() barChartLabels: Label[] = [];
  @Input() barChartData: ChartDataSets[] = [];
  @Input() chartTitle: string;
  @Input() barChartExcelData: any;
  @Input() barChartExcelFileName: string;
  @Input() barChartTypeToUse: ChartType;
  @Input() scoreAlign;
  @Input() scoreAnchor;
  @Input() canvasHeight;
  @Input() dataTableId: string;
  @Input() dataModalId: string;
  @Input() showLegend: Boolean = true;
  @Input() isPercentageData: Boolean = false;
  @Input() loaded: Boolean = false;

  public barChartOptions: any;
  public barChartType: ChartType;
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartColors: Color[] = [
    { backgroundColor: '#ffa1b5' },
    { backgroundColor: '#86c7f3' },
    { backgroundColor: '#ffe199' },
    { backgroundColor: '#ffc5a1' },
  ]

  // datatable option
  public dtElement: DataTableDirective;
  public dtBarOptions: any = {};
  public dtTrigger: Subject<any> = new Subject();

  public dataTargetName: string;

  constructor(
  ) { }

  ngOnInit(): void {
    this.barChartType = this.barChartTypeToUse;
    let flag = this.isPercentageData
    if (flag) {
      this.barChartOptions = {
        beforeInit: function (chart, options) {
          chart.legend.afterFit = function () {
            this.height += 20
          }
        },
        responsive: true,
        layout: {
          padding: {
            bottom: 20
          }
        },
        legend: {
          display: this.showLegend,
          position: 'top',
          labels: {
            padding: 10,
            fontSize: 25,
            fontWeight:'bold'
          }
        },
        title: {
          display: true,
          text: this.chartTitle,
          fontSize: 25
        },
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              max: 100
            }
          }],
          xAxes: [{
            ticks: {
              min: 0,
              max: 100,
              fontSize: 20,
              fontWeight:'bold'
            }
          }]
        },
        plugins: {
          datalabels: {
            display: true,
            anchor: this.scoreAnchor,
            align: this.scoreAlign,
            font: {
              size:28,
              weight: "bold"
            },
            formatter: function (value, context) {
              return value + '%';
            }
          }
        }
      };
    } else {
      this.barChartOptions = {
        beforeInit: function (chart, options) {
          chart.legend.afterFit = function () {
            this.height += 20
          }
        },
        responsive: true,
        layout: {
          padding: {
            bottom: 20
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
            }
          }],
          xAxes: [{
            ticks: {
              min: 0,
              fontSize: 20,
              fontWeight: 'bold'
            },

          }]
        },
        legend: {
          display: this.showLegend,
          position: 'top',

          labels: {
            padding: 10,
            fontSize: 25, 
          }
        },
        title: {
          display: true,
          text: this.chartTitle,
          fontSize: 25
        },
        plugins: {
          datalabels: {
            display: true,
            anchor: this.scoreAnchor,
            align: this.scoreAlign,
            font: {
              size: 25,
              weight: "bold"
            },
            formatter: function (value, context) {
              return value;
            }
          }
        }
      };
    }

    this.barChartPlugins = [pluginDataLabels, {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height += 40
        }
      },

    }]
    this.dtBarOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: "<'row'<'col-md-6'l>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-md-6'i><'col-md-6'p>>",
      buttons: [
        {
          extend: 'csvHtml5',
          text: 'Export as CSV',
          title: this.barChartExcelFileName,
          className: ' mr-1 btn btn-info float-right'
        }
      ]
    };
    this.dataTargetName = '#' + this.dataModalId;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  exportDataToExcel() {
    this.downloadFile(this.barChartExcelData.data, this.barChartExcelData.labels, this.barChartExcelFileName);
  }

  downloadFile(data: Array<Object>, headerList: Array<String>, filename: String) {
    let csvData = this.convertToCSV(data, headerList);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=UTF-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  convertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

}
