import { Component, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MerchOperationService } from 'src/app/_services/merch-operation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-merch-city',
  templateUrl: './merch-city.component.html',
  styleUrls: ['./merch-city.component.scss']
})
export class MerchCityComponent implements OnInit {

  Cities: Array<any> = [];
  cityForm: FormGroup;
  accessToken: string;

  updating: boolean = false;
  cityIdToUpdate: Number = null;
  governorateDropdownSettings: IDropdownSettings;

  @ViewChild(DataTableDirective, {
    static: false,
  })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public governorates: Array<any> = [];


  constructor(
    private fb: FormBuilder,
    private operationService: MerchOperationService
  ) { }

  ngOnInit(): void {
    this.accessToken = localStorage.getItem('accessToken');
    this.loadGovernorates();

    this.cityForm = this.fb.group({
      name: ['', [Validators.required]],
      name_ar: [''],
      governorate: ['', [Validators.required]],
      governorateshow: ['', [Validators.required]]
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true,
      destroy: true
    }

    this.governorateDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.listAllCities();
  }

  ngAfterViewInit(): void {
    if (!this.dtElement.dtInstance) {
      this.dtTrigger.next();
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  loadGovernorates() {
    Swal.showLoading();
    this.operationService.getGovernorates().subscribe(response => {
      Swal.close();
      if (response.success) {
        this.governorates = response.data?.results;
        console.log(this.governorates)
        this.rerender();
      } else {
        Swal.fire({
          icon: 'error',
          title: response.error.error_message || "Problem in fetching governorates!"
        });
      }
    }, error => {
      Swal.close()
      Swal.fire({
        icon: 'error',
        title: error.error.error_message || "Problem in fetching governorates!"
      });
    })
  }

  listAllCities() {
    Swal.showLoading();
    this.operationService.getCities()
      .subscribe(response => {
        Swal.close();
        if (response.success) {
          this.Cities = response.data?.results;

          console.log(this.Cities)
          this.rerender();
        } else {
          Swal.fire({
            icon: 'error',
            title: response.error.error_message || "Problem in fetching Cities!"
          });
        }
      }, error => {
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: error.error.error_message || "Problem in fetching Cities!"
        });
      })
  }

  removecity(cityId) {
    this.operationService.removeCity(cityId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "city deleted successfully!"
        }).then(res => {
          this.listAllCities();
        })
      })
      .catch(error => {
        Swal.fire({
          icon: "error",
          titleText: "Something went wrong while deleting city!"
        })
      })
  }

  updatecity() {
    if (this.cityForm.invalid) {
      for (var i in this.cityForm.controls) {
        this.cityForm.controls[i].markAsDirty();
        this.cityForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      if (this.cityIdToUpdate) {
        this.operationService.updateCity(this.cityIdToUpdate, this.cityForm.value)
          .subscribe(
            response => {
              Swal.close();
              if (response.success) {
                Swal.fire({
                  icon: 'success',
                  title: "city updated successfully!"
                }).then(() => {
                  this.cityIdToUpdate = null;
                  this.listAllCities();
                  this.updating = false;
                  this.cityForm.reset();
                })
              } else {
                Swal.fire({
                  icon: 'error',
                  title: response.error_message || "Problem in updating city!"
                });
              }
            },
            error => {
              Swal.close();
              Swal.fire({
                icon: 'error',
                title: error.error.error_message || "Problem in adding city!"
              });
            }
          )
      }
    }

  }

  addCity() {
    this.cityForm.controls['governorate'].setValue(this.cityForm.controls['governorateshow'].value[0].id)
    if (this.cityForm.invalid) {
      for (var i in this.cityForm.controls) {
        this.cityForm.controls[i].markAsDirty();
        this.cityForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      this.operationService.addCity(this.cityForm.value)
        .subscribe(
          response => {
            Swal.close();
            if (response.success) {
              Swal.fire({
                icon: 'success',
                title: "city added successfully!"
              }).then(() => {
                this.listAllCities();
                this.updating = false;
                this.cityForm.reset();
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: response.error_message || "Problem in adding city!"
              });
            }
          },
          error => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: error.error.error_message || "Problem in adding city!"
            });
          }
        )
    }
  }

  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }
  // helper methods
  fetchcity(city) {
    this.updating = true;
    this.cityIdToUpdate = city.id || null;
    this.cityForm = this.fb.group({
      name: [city.name, [Validators.required]],
      name_ar: [city.name_ar],
      governorate: [city.governorate, [Validators.required]],
      governorateshow: [[{
        'id': city.governorate,
        'name': city.governorate_name,
      }], [Validators.required]]
    });
  }

  toggleUpdateMode() {
    this.updating = false;
    this.cityForm.reset();
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

}
