import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldWorkerService } from 'src/app/_services/field-worker.service';
import { MerchOperationService } from 'src/app/_services/merch-operation.service';
import { getaudit, setaudit } from 'src/app/_shared/utils';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-audit-edit',
  templateUrl: './audit-edit.component.html',
  styleUrls: ['./audit-edit.component.scss']
})
export class AuditEditComponent implements OnInit {
  audit = getaudit();
  auditForm: FormGroup; // Define a FormGroup for your form
  id: any

  constructor(private fieldService: FieldWorkerService, private fb: FormBuilder, private merch_service: MerchOperationService, private route: ActivatedRoute, private router: Router) {
  }


  promotionList = ['gift', 'buyone', 'discount']
  promotion_default = []

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
  };
  ngOnInit(): void {
    // Subscribe to the route parameter to get the id
    this.route.params.subscribe((params) => {
      this.id = params.id; // Access the id parameter 
    });

    this.audit = JSON.parse(this.audit)
    if (this.audit.status.includes('gift')) {
      this.promotion_default.push('gift')
    } if (this.audit.status.includes('buyone')) {
      this.promotion_default.push('buyone')
    }
     if (this.audit.status.includes('discount')) {
      this.promotion_default.push('discount')
    }

    this.auditForm = this.fb.group({
      // sku: new FormControl(this.audit.sku_name),
      // category_name: new FormControl(this.audit.category_name), 
      is_available: new FormControl(this.audit.is_available), // Set default values using audit data
      faces_no: new FormControl(this.audit.faces_no),
      other_brand: new FormControl(this.audit.other_brand),
      shelf_balance: new FormControl(this.audit.shelf_balance),
      shelf_space: new FormControl(this.audit.shelf_space),
      secondary_display: new FormControl(this.audit.secondary_display),
      store_balance: new FormControl(this.audit.store_balance),
      price_out: new FormControl(this.audit.price_out),
      promotion: new FormControl(this.promotion_default),
    });
  }


  cancel() {
    setaudit('')
    this.router.navigate(['/merch/audit-response/', this.id])
  }
  onFormSubmit() {
    // Get the form data and log it to the console
    const formData = this.auditForm.value;
    console.log('Form Data:', formData, this.auditForm);
    this.merch_service.edit_audit({ ...formData, status: this.auditForm.controls['promotion'].value }, this.audit.id).subscribe((data) => {
      if (data.success) {
        Swal.fire({
          title: data?.data?.detail,
          icon: 'success', // You can use 'success', 'error', 'warning', 'info', etc.
          confirmButtonText: 'OK',
        });
        this.router.navigate(['/merch/audit-response/', this.id])
      } else {
        Swal.fire({
          title: data?.data?.detail,
          icon: 'error', // You can use 'success', 'error', 'warning', 'info', etc.
          confirmButtonText: 'OK',
        });
      }

    })
    console.log('Form Data:', formData);
  }
}
