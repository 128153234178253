<!DOCTYPE html>
<html lang="en">

<body>
  <div id="wrapper">
    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Single Audit Responses'"></app-top-bar>
    <!-- end Topbar -->
  </div>
  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">

    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div class="mb-0">
            <app-top-title [title]="'Single Audit Responses'"
              [subTitle]="'You can View Single Audit\'s history data here.'"></app-top-title>
          </div>
          <div class="mt-3 p-3 bg-white mb-5">
            <div class="mt-2 mb-3">
              <div class="float-left">
                <div>
                  <button class="btn btn-danger mr-2" (click)="deleteAuditData()">
                    Delete
                  </button>
                </div>
                <div class="mt-2 mb-2">
                </div>
              </div>
              <div class="float-right">

                <div id="export_csv" class="btn btn-lg btn-sm btn-primary rounded-pill py-2 px-4 mr-3 mt-2"
                  style="font-size: 0.875rem;" (click)="exportData()">
                  Export
                </div>
              </div>
            </div>

            <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" name="id" (click)="selectAll()" [checked]="isSelectAllChecked">
                  </th>
                  <th>SKU</th>
                  <th>Category</th>
                  <th>Brand</th>
                  <th>Available</th>
                  <th>Face Number</th>
                  <th>Other Brand</th>
                  <th>Shelf Balance</th>
                  <th>Shelf Space</th>
                  <th>Secondary Display</th>
                  <th>Store Balance</th>
                  <th>Price Out</th>
                  <th>Promotions</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let auditResponse of auditResponseData">
                  <td>
                    <input type="checkbox" name="id" (change)='rowSelected(auditResponse?.id)'
                      [checked]="auditResponse.isChecked">
                  </td>
                  <td> {{auditResponse.sku_name}}</td>
                  <td> {{ auditResponse.category_name}}</td>
                  <td> {{ auditResponse.brand_name}}</td>
                  <td> {{auditResponse.is_available}}</td>
                  <td> {{ auditResponse.faces_no}}</td>
                  <td> {{ auditResponse.other_brand}}</td>
                  <td> {{ auditResponse.shelf_balance}}</td>
                  <td> {{ auditResponse.shelf_space}}</td>
                  <td> {{ auditResponse.secondary_display}}</td>
                  <td> {{ auditResponse.store_balance}}</td>
                  <td> {{ auditResponse.price_out}}</td>
                  <td> {{ auditResponse.status?auditResponse.status:'none'}}</td>
                  <td> <a (click)="edit_Audit(auditResponse,auditId)" style="color: dodgerblue;cursor: pointer;">Edit</a></td>
                </tr>
              </tbody>
            </table>
 
          </div>
        </div> <!-- container-fluid -->

      </div> <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->
  </div>
  <!-- END wrapper -->
</body>

</html>