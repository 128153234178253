<!DOCTYPE html>
<html lang="en">

<body>
    <div id="wrapper">
        <!-- Topbar Start -->
        <app-top-bar [pageTitle]="'Operations'" (searchText)="searchText($event)"></app-top-bar>
        <!-- end Topbar -->
    </div>
    <!-- Begin page -->
    <div id="wrapper" style="background-color: '#ebeff2';">



        <!-- ========== Left Sidebar Start ========== -->
        <app-left-side-bar></app-left-side-bar>
        <!-- Left Sidebar End -->

        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="content-page">
            <div class="content">

                <!-- Start Content-->
                <div class="container-fluid">

                    <div class="mb-0">
                        <app-top-title [title]="'Add governorate to Operations'"
                            [subTitle]="'You can add Governorate to operations from here'"></app-top-title>
                    </div>
                    <div class="bg-white">

                        <form class="" role="form" [formGroup]="governorateForm"
                            (ngSubmit)="updating?updategovernorate():addGovernorate()">
                            <div class="row p-4">

                                <div class="col-md-4" style="width: 100%;">
                                    <div class="form-group">
                                        <label class="col-form-label" for="region"> Region <span
                                                style="color:#ff0000">*</span></label>
                                        <ng-multiselect-dropdown name="region" id="selRegion"
                                            [placeholder]="'Select Region'" [data]="regions"
                                            [settings]="regionDropdownSettings"
                                            [formControl]="governorateForm.controls['regionshow']"
                                            [class.valid]="governorateForm.get('regionshow').valid &&
                                                                (governorateForm.get('regionshow').dirty || governorateForm.get('regionshow').touched)"
                                            [class.invalid]="governorateForm.get('regionshow').invalid &&
                                                                (governorateForm.get('regionshow').dirty || governorateForm.get('regionshow').touched)">
                                        </ng-multiselect-dropdown>

                                        <div
                                            *ngIf="governorateForm.get('regionshow').invalid &&
                                                                   governorateForm.get('regionshow').errors &&
                                                                   (governorateForm.get('regionshow').dirty || governorateForm.get('regionshow').touched)">
                                            <small class="text-danger"
                                                *ngIf="governorateForm.get('regionshow').hasError('required')">
                                                Region is required. </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4" style="width: 100%;">
                                    <div class="form-group">
                                        <label class="col-form-label" for="governorate">governorate<span
                                                style="color:#ff0000">*</span></label>
                                        <input placeholder="Enter Region Name" type="text" class="form-control"
                                            id="governorate" formControlName="name" [class.valid]="governorateForm.get('name').valid &&
                        (governorateForm.get('name').dirty || governorateForm.get('name').touched)" [class.invalid]="governorateForm.get('name').invalid &&
                        (governorateForm.get('name').dirty || governorateForm.get('name').touched)">
                                        <div
                                            *ngIf="governorateForm.get('name').invalid && governorateForm.get('name').errors && (governorateForm.get('name').dirty || governorateForm.get('name').touched)">
                                            <small class="text-danger"
                                                *ngIf="governorateForm.get('name').hasError('required')">
                                                governorate name is required. </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3" style="width: 100%;">
                                    <div class="form-group">
                                        <label class="col-form-label" for="governorate">governorate (Arabic)</label>
                                        <input placeholder="Enter Region Name (Arabic)" type="text" class="form-control"
                                            id="governorate" formControlName="name_ar" [class.valid]="governorateForm.get('name_ar').valid &&
                        (governorateForm.get('name_ar').dirty || governorateForm.get('name_ar').touched)"
                                            [class.invalid]="governorateForm.get('name_ar').invalid &&
                        (governorateForm.get('name_ar').dirty || governorateForm.get('name_ar').touched)">
                                    </div>
                                </div>
                            </div>

                            <div class="row px-5 pb-3 d-flex">
                                <div class="form-group">
                                    <button type="submit"
                                        class="btn btn-success px-5 mr-2">{{updating?'Update':'Add'}}</button>
                                    <button (click)="toggleUpdateMode()" type="button"
                                        class="btn btn-danger px-5">Cancel</button>
                                </div>
                            </div>

                        </form>
                        <!-- end row -->
                    </div>


                    <div class="mt-3 p-3 bg-white mb-5">
                        <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th>governorate Id</th>
                                    <th>Region Name</th>
                                    <th>governorate Name</th>
                                    <th>governorate Name (Arabic)</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let governorate of Governorates">
                                    <td>{{ governorate.id }}</td>
                                    <td>{{ governorate.region_name}}</td>
                                    <td>{{ governorate.name }}</td>
                                    <td>{{ governorate.name_ar }}</td>
                                    <td>
                                        <a [routerLink]="" (click)="fetchgovernorate(governorate)"
                                            style="color:'blue';">Edit</a>
                                    </td>
                                    <td>
                                        <a [routerLink]="" (click)="removegovernorate(governorate.id)"
                                            style="color:'red';"> Delete </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> <!-- container-fluid -->

            </div> <!-- content -->

            <!-- Footer Start -->
            <app-footer></app-footer>
            <!-- end Footer -->

        </div>

        <!-- ============================================================== -->
        <!-- End Page content -->
        <!-- ============================================================== -->


    </div>
    <!-- END wrapper -->

</body>

</html>