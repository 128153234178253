import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { MerchOperationService } from '../../_services/merch-operation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { setaudit } from 'src/app/_shared/utils';

@Component({
  selector: 'app-merch-audit-response',
  templateUrl: './merch-audit-response.component.html',
  styleUrls: ['./merch-audit-response.component.scss']
})
export class MerchAuditResponseComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  public previousUrl: string;
  public nextUrl: string;

  public gLimit = 10;
  public gOffset = 0;
  // public count: number;

  isSelectAllChecked = false;

  public auditResponseData: any;
  accessToken: string;

  dashboardForm: FormGroup;

  dateStart: string;
  date_range: any;
  format: 'MM/DD/YYYY';
  filterValue: string;
  alwaysShowCalendars: boolean;
  // minDate = moment('2018-01-01');
  minDate = moment();
  maxDate = moment().add(0, 'days');
  showRangeLabelOnInput = true;
  public dateAfter: any;
  public dateBefore: any;
  public auditId: string;

  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')]
  };
  selected = { start: moment().toDate(), end: moment().toDate() };
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private merchOperationService: MerchOperationService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true,
      destroy: true
    };

    this.dashboardForm = this.fb.group({
      // date_range: [{ date_after: moment('2018-01-01'), date_before: moment('2018-03-30') }], // default This Month
      date_range: []
      // date_range: [{ date_after: moment().toDate(), date_before: moment().endOf('month').toDate() }], // default This Month
    });
    this.auditId = this.route.snapshot.params.id || null;

    this.viewAuditResponseData(this.gLimit, this.gOffset, '', '');
    this.accessToken = localStorage.getItem('accessToken');
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  // PM Methods

  edit_Audit(audit, Id) {
    setaudit(JSON.stringify(audit))
    this.router.navigate(['/merch/audit-response/edit/' + Id]);
  }

  viewAuditResponseData(limit?: number, offset?: number, dateBefore?: string, dateAfter?: string) {
    Swal.showLoading();
    this.merchOperationService.getMerchAuditResponse(this.auditId, limit, offset, dateBefore, dateAfter)
      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.auditResponseData = res && res.data ? res.data.results : [];
          this.isSelectAllChecked = false;
          this.auditResponseData.map(snapDatum => snapDatum.isChecked = false);

          this.previousUrl = res && res.data ? res.data.previous : null;
          this.nextUrl = res && res.data ? res.data.next : null;

          // this.count = res && res.data ? res.data.count : 0;

          this.rerender();
        } else {
          this.auditResponseData = [];
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Something went wrong in fetching snap data!',
          timer: 1500
        });
      });
  }

  // helper methods

  changePageLength(limit: number) {
    this.gLimit = limit;
    this.viewAuditResponseData(this.gLimit, this.gOffset, this.dateBefore, this.dateAfter);
  }

  handlePagination(paginationString: string) {

    Swal.showLoading();

    if (paginationString == 'next') {
      this.http.get<any>(this.nextUrl)
        .subscribe(response => {
          Swal.close();
          this.auditResponseData = response && response.data ? response.data.results : [];
          this.auditResponseData.map(snapDatum => snapDatum.isChecked = false);

          this.previousUrl = response && response.data ? response.data.previous : null;
          this.nextUrl = response && response.data ? response.data.next : null;

          const { limit, offset } = this.extractQueryParams(this.nextUrl);
          this.gOffset = parseInt(offset || '0') - parseInt(limit || '0');

          this.rerender();
        }, error => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: error.error_message || 'Something went wrong in fetching snap data!',
            timer: 1500
          });
        });
    }

    if (paginationString == 'previous') {
      this.http.get<any>(this.previousUrl)
        .subscribe(response => {
          Swal.close();
          this.auditResponseData = response && response.data ? response.data.results : [];
          this.auditResponseData.map(snapDatum => snapDatum.isChecked = false);

          this.previousUrl = response && response.data ? response.data.previous : null;
          this.nextUrl = response && response.data ? response.data.next : null;

          const { limit, offset } = this.extractQueryParams(this.nextUrl);
          this.gOffset = parseInt(offset || '0') - parseInt(limit || '0');

          this.rerender();
        }, error => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: error.error_message || 'Something went wrong in fetching snap data!',
            timer: 1500
          });
        });
    }
  }

  selectAll() {
    this.isSelectAllChecked = !this.isSelectAllChecked;

    if (this.isSelectAllChecked) {
      this.auditResponseData.map(snapDatum => snapDatum.isChecked = true);
    } else {
      this.auditResponseData.map(snapDatum => snapDatum.isChecked = false);
    }
  }

  callservice(id, index, array) {
    return this.merchOperationService.removeAuditResponse(id, this.accessToken)
      .then(res => {
        // Check if it's the last iteration
        if (index === array.length - 1) {
          // Show success Swal after the loop is done
          Swal.fire({
            icon: 'success',
            title: 'Records deleted successfully!'
          }).then(() => {
              this.viewAuditResponseData()
          });
        }
      })
      .catch(error => {
        // Show error Swal if something goes wrong in the loop
        Swal.fire({
          icon: 'error',
          titleText: `Error deleting record with ID ${id}`
        });
      });
  }

  deleteAuditData() {
    const recordsToBeDeleted = this.auditResponseData.filter(snapDatum => snapDatum.isChecked == true);

    // Show the warning Swal once before the loop
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to delete?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Promise.all(recordsToBeDeleted.map((record, index, array) => {
          return this.callservice(record.id, index, array);
        }));
      }
    });
  }


  rowSelected(id) {
    this.auditResponseData.map(data => {
      if (data.id == id) {
        data.isChecked = !data.isChecked;
      }
    });
  }


  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }
  extractQueryParams(url: string): { limit: string, offset: string } {

    const queryParamsString = url.split('?')[1];

    const limitString = queryParamsString.split('&')[0];
    const limit = limitString.split('=')[1];

    const offsetString = queryParamsString.split('&')[1];
    const offset = offsetString.split('=')[1] || '0';

    return {
      limit,
      offset
    };
  }

  exportData() {
    Swal.showLoading();
    this.http.get(`${environment.baseUrl}/merch/submit-survey/export?audit=${this.auditId}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
      headers: new HttpHeaders()
        .set('Authorization', this.accessToken)
    })
      .subscribe(
        (response: HttpResponse<Blob>) => {
          Swal.close();
          console.log(response.headers);
          const filename = `${this.auditId}_merch_audit.csv`;
          const binaryData = [];
          binaryData.push(response.body);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        },
        error => {
          Swal.close();
          console.log(error);
        }
      );
  }
}
