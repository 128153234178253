<!DOCTYPE html>
<html lang="en">

<body>

  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">

    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Field Work'" (searchText)="searchText($event)"></app-top-bar>
    <!-- end Topbar -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

          <div>
            <app-top-title [title]="'View field work agent'"
              [subTitle]="'You can view list of field work agents and modify their accounts'"></app-top-title>
          </div>
          <div class="mt-4 p-4" style="background-color: white;">
            <div class="mt-2 mb-3">
              <a routerLink="/create-field-work"
                class="btn btn-info rounded-pill pl-sm-1 pr-sm-1 pl-md-4 pr-md-4">Create</a> &nbsp;
            </div>

            <!-- end row -->
            <table  class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="fieldWorkers">
              <thead>
                <tr>
                  <th style="width: 70px;">User ID</th>
                  <th style="width: 130px;">Fieldworker Name</th>
                  <th>Country of operation</th>
                  <th>City of operation</th>
                  <th style="width: 70px;">Total audits</th>
                  <th style="width: 70px;">Edit</th>
                  <th style="width: 70px;">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of fieldWorkers">
                  <td data-column="User ID"><a [routerLink]="['/merch/audits']" [queryParams]="{user_agent: user.id}"> {{ user.id }}</a></td>
                  <td data-column="Fieldworker name"> <a [routerLink]="['/merch/audits']" [queryParams]="{user_agent: user.id}">{{ user.fullname }}</a></td>
                  <td data-column="Country of operation">
                    {{user.country_of_operation.join(', ') }}
                  </td>
                  <td data-column="City of operation">
                    {{user.city_of_operation.join(', ') }}
                  </td>
                  <td data-column="Total audits">{{user.audit_count}}</td>
                  <td>
                    <a [routerLink]="['/create-field-work', user.id]"> Edit </a>
                  </td>
                  <td>
                    <a [routerLink]="" (click)="removeFieldWorkerById(user.id)" style="color:'red';"> Delete </a>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
          <br /><br />
        </div>
        <!-- container-fluid -->

      </div>
      <!-- content -->

      <!-- Footer Start -->
      <app-footer></app-footer>
      <!-- end Footer -->

    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->

  </div>
  <!-- END wrapper -->

</body>

</html>
