<!DOCTYPE html>
<html lang="en">

<body>
    <div id="wrapper">
        <!-- Topbar Start -->
        <!-- <app-top-bar [pageTitle]="'Merchandizing User audits'" (searchText)="searchText($event)"> -->
        <app-top-bar [pageTitle]="'Merchandizing User audits'">
        </app-top-bar>
        <!-- end Topbar -->
    </div>
    <!-- Begin page -->
    <div id="wrapper" style="background-color: '#ebeff2';">

        <!-- ========== Left Sidebar Start ========== -->
        <app-left-side-bar></app-left-side-bar>
        <!-- Left Sidebar End -->

        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="content-page">
            <div class="content">

                <!-- Start Content-->
                <div class="container-fluid">

                    <div class="mb-0">
                        <app-top-title [title]="'Edit User\'s audit history'"
                            [subTitle]="'You can Edit Users Audit\'s history data here.'"></app-top-title>
                    </div>
                    <div class="mt-3 p-3 bg-white mb-5">
                        <div class="mt-4 p-2" style="background-color: white;">
                            <!-- end row -->

                            <div class="row">
                                <div class="col-12">
                                    <div class="card-box">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="p-2">
                                                    <form class="form-horizontal" role="form" [formGroup]="auditForm"
                                                        (ngSubmit)="onFormSubmit()">
                                                        <!-- <div class="form-group row">
                                                            <label class="col-md-3 col-form-label" for="sku">SKU<span
                                                                    style="color: #ff0000">*</span></label>
                                                            <div class="col-md-9">
                                                                <input type="text" id="sku" class="form-control"
                                                                    placeholder="SKU" formControlName="sku">
                                                            </div>
                                                        </div>

                                                        <div class="form-group row">
                                                            <label class="col-md-3 col-form-label"
                                                                for="category">Category<span
                                                                    style="color: #ff0000">*</span></label>
                                                            <div class="col-md-9">
                                                                <input type="text" id="category" class="form-control"
                                                                    placeholder="Category"
                                                                    formControlName="category_name">
                                                            </div>
                                                        </div>

                                                        <div class="form-group row">
                                                            <label class="col-md-3 col-form-label"
                                                                for="brand">Brand<span
                                                                    style="color: #ff0000">*</span></label>
                                                            <div class="col-md-9">
                                                                <input type="text" id="brand" class="form-control"
                                                                    placeholder="Brand" formControlName="brand_name">
                                                            </div>
                                                        </div> -->

                                                        <div class="form-group row">
                                                            <label class="col-md-3 col-form-label"
                                                                for="is_available">Available<span
                                                                    style="color: #ff0000">*</span></label>
                                                            <div class="col-md-9">
                                                                <select id="is_available"
                                                                    formControlName="is_available">
                                                                    <option value="true">Yes</option>
                                                                    <option value="false">No</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="form-group row">
                                                            <label class="col-md-3 col-form-label"
                                                                for="is_available">Promotions<span
                                                                    style="color: #ff0000">*</span></label>
                                                            <div class="col-md-9">
                                                                <ng-multiselect-dropdown formControlName="promotion"
                                                                    [placeholder]="'Select Promotion'"
                                                                    [data]="promotionList"
                                                                    [settings]="dropdownSettings"></ng-multiselect-dropdown>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-md-3 col-form-label" for="faces_no">Face
                                                                Number<span style="color: #ff0000">*</span></label>
                                                            <div class="col-md-9">
                                                                <input type="text" id="faces_no" class="form-control"
                                                                    placeholder="Face Number"
                                                                    formControlName="faces_no">
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-md-3 col-form-label"
                                                                for="other_brand">Other Brand<span
                                                                    style="color: #ff0000">*</span></label>
                                                            <div class="col-md-9">
                                                                <input type="text" id="other_brand" class="form-control"
                                                                    placeholder="Otheqr Brand"
                                                                    formControlName="other_brand">
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-md-3 col-form-label"
                                                                for="shelf_balance">Shelf Balance<span
                                                                    style="color: #ff0000">*</span></label>
                                                            <div class="col-md-9">
                                                                <input type="text" id="shelf_balance"
                                                                    class="form-control" placeholder="Shelf Balance"
                                                                    formControlName="shelf_balance">
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-md-3 col-form-label"
                                                                for="shelf_space">Shelf Space<span
                                                                    style="color: #ff0000">*</span></label>
                                                            <div class="col-md-9">
                                                                <input type="text" id="shelf_space" class="form-control"
                                                                    placeholder="Shelf Space"
                                                                    formControlName="shelf_space">
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-md-3 col-form-label"
                                                                for="secondary_display">Secondary Display<span
                                                                    style="color: #ff0000">*</span></label>
                                                            <div class="col-md-9">
                                                                <input type="text" id="secondary_display"
                                                                    class="form-control" placeholder="Secondary Display"
                                                                    formControlName="secondary_display">
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-md-3 col-form-label"
                                                                for="store_balance">Store Balance<span
                                                                    style="color: #ff0000">*</span></label>
                                                            <div class="col-md-9">
                                                                <input type="text" id="store_balance"
                                                                    class="form-control" placeholder="Store Balance"
                                                                    formControlName="store_balance">
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-md-3 col-form-label" for="price_out">Price
                                                                Out<span style="color: #ff0000">*</span></label>
                                                            <div class="col-md-9">
                                                                <input type="text" id="price_out" class="form-control"
                                                                    placeholder="Price Out" formControlName="price_out">
                                                            </div>
                                                        </div>
                                                        <!-- Add the remaining form fields, making sure to match the field names -->
                                                        <!-- ... -->

                                                        <div style="float: right;">
                                                            <button type="submit"
                                                                class="btn btn-success rounded-pill pl-4 pr-4">Save</button>
                                                            &nbsp;
                                                            <button type="button"
                                                                class="btn btn-danger rounded-pill pl-4 pr-4"
                                                                (click)="cancel()">Cancel</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Footer Start -->
            <app-footer></app-footer>
            <!-- end Footer -->
        </div>

        <!-- ============================================================== -->
        <!-- End Page content -->
        <!-- ============================================================== -->
    </div>
    <!-- END wrapper -->
</body>

</html>
<!-- 
<th>Available</th>
<th>Face Number</th>
<th>Other Brand</th>
<th>Shelf Balance</th>
<th>Shelf Space</th>
<th>Secondary Display</th>
<th>Store Balance</th>
<th>Price Out</th> -->