<!DOCTYPE html>
<html lang="en">

<body>

  <!-- Begin page -->
  <div id="wrapper" style="background-color: '#ebeff2';">

    <!-- Topbar Start -->
    <app-top-bar [pageTitle]="'Merchandizing dashboard'"></app-top-bar>
    <!-- end Topbar -->

    <!-- ========== Left Sidebar Start ========== -->
    <app-left-side-bar></app-left-side-bar>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">
        <div style="position:fixed;z-index: 1000;top: 70px;border-top: 5px solid #eee;">
          <app-merch-filter (scrollTo)="scrollToItem($event)" (filterAppliedEvent)="filterApplied($event)"
            (resetFilter)="resetFilter()">
          </app-merch-filter>
        </div>
        <!-- Start Content-->
        <div class="price-monitor-chart-view" style="margin-top: 22vh;">
          <div #target1 class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="availabilityBarChartData" [canvasHeight]="height"
                [loaded]="availabilityBarChartDataLoaded" [barChartLabels]="availabilityBarChartLabels"
                [chartTitle]="'Availability'" [barChartExcelData]="availabilityBarChartExcelData"
                [barChartExcelFileName]="'availability_report'" [barChartTypeToUse]="'bar'" [scoreAlign]="'end'"
                [scoreAnchor]="'end'" [dataTableId]="'availabilityBarChartData'"
                [dataModalId]="'availabilityBarChartData'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>

          <div #target2 class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="outOfStockBarChartData" [canvasHeight]="height"
                [loaded]="outOfStockBarChartDataLoaded" [barChartLabels]="outOfStockBarChartLabels"
                [chartTitle]="'Out of Stock'" [barChartExcelData]="outOfStockBarChartExcelData"
                [barChartExcelFileName]="'outOfStock_report'" [barChartTypeToUse]="'bar'" [scoreAlign]="'end'"
                [scoreAnchor]="'end'" [dataTableId]="'outOfStockBarChartData'" [dataModalId]="'outOfStockBarChartData'"
                [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>

          <div #target3 class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="selfShareBarChartData" [canvasHeight]="height"
                [loaded]="selfShareBarChartDataLoaded" [barChartLabels]="selfShareBarChartLabels"
                [chartTitle]="'Shelf Share'" [barChartExcelData]="selfShareBarChartExcelData"
                [barChartExcelFileName]="'selfShare_report'" [barChartTypeToUse]="'bar'" [scoreAlign]="'end'"
                [scoreAnchor]="'end'" [dataTableId]="'selfShareBarChartData'" [dataModalId]="'selfShareBarChartData'"
                [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>
          <div #target4 class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="selfBalanceBarChartData" [canvasHeight]="height"
                [loaded]="selfBalanceBarChartDataLoaded" [barChartLabels]="selfBalanceBarChartLabels"
                [chartTitle]="'Shelf Balance'" [barChartExcelData]="selfBalanceBarChartExcelData"
                [barChartExcelFileName]="'selfBalance_report'" [barChartTypeToUse]="'bar'" [scoreAlign]="'end'"
                [scoreAnchor]="'end'" [dataTableId]="'selfBalanceBarChartData'"
                [dataModalId]="'selfBalanceBarChartData'" [isPercentageData]="true">
              </app-barchart>
            </div>
          </div>

          <div #target5 class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="secondaryDisplayBarChartData" [canvasHeight]="height"
                [loaded]="secondaryDisplayBarChartDataLoaded" [barChartLabels]="secondaryDisplayBarChartLabels"
                [chartTitle]="'Secondary Display'" [barChartExcelData]="secondaryDisplayBarChartExcelData"
                [barChartExcelFileName]="'secondaryDisplay_report'" [barChartTypeToUse]="'bar'" [scoreAlign]="'end'"
                [scoreAnchor]="'end'" [dataTableId]="'secondaryDisplayBarChartData'"
                [dataModalId]="'secondaryDisplayBarChartData'">
              </app-barchart>
            </div>
          </div>
          <div #target6 class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="storeBalanceBarChartData" [canvasHeight]="height"
                [loaded]="storeBalanceBarChartDataLoaded" [barChartLabels]="storeBalanceBarChartLabels"
                [chartTitle]="'Store Balance'" [barChartExcelData]="storeBalanceBarChartExcelData"
                [barChartExcelFileName]="'storeBalance_report'" [barChartTypeToUse]="'bar'" [scoreAlign]="'end'"
                [scoreAnchor]="'end'" [dataTableId]="'storeBalanceBarChartData'"
                [dataModalId]="'storeBalanceBarChartData'">
              </app-barchart>
            </div>
          </div>

          <div #target7 class="row">
            <div class="col-lg-12">
              <h2 style="color: #666;font-size:1.5rem;text-align: center;margin-top: 20px;" *ngIf="loadingprice">Calculating Price Measures...</h2>
              <div style="background-color: #fff !important; padding: 20px;margin-bottom: 10px;" *ngIf="!filterarary.brand_ids">
                <h2 style="color: #666;font-size:1.5rem;text-align: center;margin-top: 20px;">Price Measure</h2>
                <h2 style="text-align: center; color: rgb(190, 6, 6); margin: 50px;">Please select a brand to view this
                  chart!</h2>
              </div>
              <div>
                <app-barchart *ngIf="filterarary.brand_ids" [barChartData]="priceMinMaxBarChartData" [canvasHeight]="height" [loaded]="true"
                  [barChartLabels]="priceMinMaxBarChartLabels" [chartTitle]="'Price Measure'"
                  [barChartExcelData]="priceMinMaxBarChartExcelData"
                  [barChartExcelFileName]="'price_monitor_min_max_report'" [barChartTypeToUse]="'bar'"
                  [scoreAlign]="'end'" [scoreAnchor]="'end'" [dataTableId]="'priceMinMaxBarChartData'"
                  [dataModalId]="'priceMinMaxBarChartData'">
                </app-barchart>

              </div>
            </div>
          </div>

          <div #target8 class="row">
            <div class="col-lg-12">
              <app-barchart [barChartData]="promotionBarChartData" [canvasHeight]="height" [loaded]="true"
                [barChartLabels]="promotionBarChartLabels" [chartTitle]="'Promotions'"
                [barChartExcelData]="promotionBarChartExcelData" [barChartExcelFileName]="'promotion_report'"
                [barChartTypeToUse]="'bar'" [scoreAlign]="'end'" [scoreAnchor]="'end'"
                [dataTableId]="'promotionBarChartData'" [dataModalId]="'promotionBarChartData'">
              </app-barchart>
            </div>
          </div>

          <div class="row images_container">
            <span class="title">Pictures</span>
            <div class="col-lg-12">
              <div class="audit-image" style="max-width: 200px;min-height: 300px;" *ngFor="let item of auditImageData">
                <img (click)="openDialog(item.image, item.category, item.branch, item.store)" style="cursor: pointer;"
                  width="200" height="200" [src]="item.image" alt="audit image" />
                <div class="audit-image-name">{{item.category}} - {{item.store}}</div>
              </div>
            </div>
            <div class="row mr-2 mt-4 flex d-flex justify-content-end"
              style="margin-top: -15px;float: right;width: 100%;">
              <button class="btn btn-warning mr-2" (click)="loadPrevious()" *ngIf="previousCursor!==null">
                Previous</button>
              <button *ngIf="nextCursor!==null" class="btn btn-primary" (click)="loadNext()">
                Next</button>
            </div>
          </div>

        </div>

      </div>


    </div>

  </div>

  <!-- ============================================================== -->
  <!-- End Page content -->
  <!-- ============================================================== -->

  <!-- Footer Start -->
  <app-footer></app-footer>
  <!-- end Footer -->


  <!-- END wrapper -->


</body>

</html>