import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { MerchOperationService } from 'src/app/_services/merch-operation.service';
import { SnapOperationService } from 'src/app/_services/snap-operation.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-merch-category',
  templateUrl: './merch-category.component.html',
  styleUrls: ['./merch-category.component.scss']
})
export class MerchCategoryComponent implements OnInit ,AfterViewInit{

  form: FormGroup;
  categories: Array<any> = [];
  accessToken: string;
  categoryForm: FormGroup;
  categoryIdToUpdate: Number = null;
  updating: boolean = false;
  constructor(private fb: FormBuilder, private operationService: MerchOperationService) { }

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();

  ngOnInit(): void {
    this.accessToken = localStorage.getItem('accessToken');

    this.categoryForm = this.fb.group({
      name: ['', Validators.required]
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'ltipr',
      stateSave: true
    }

    this.listAllCategories();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  listAllCategories() {
    Swal.showLoading();
    this.operationService.getCategory()
      .subscribe(response => {
        Swal.close();
        if (response.success) {
          this.categories = response.data?.results;
          this.rerender();
        } else {
          this.handleNotification(response.error.error_message || "Problem in fetching category.", true)
        }
      }, error => {
        Swal.close()
        this.handleNotification(error.error.error_message || "Problem in fetching category.", true);
      })
  }

  removeCategory(categoryId) {
    this.operationService.removeCategory(categoryId, this.accessToken)
      .then(res => {
        Swal.fire({
          icon: "success",
          titleText: "Category deleted successfully!"
        }).then(res => {
          this.listAllCategories();
        })
      })
      .catch(error => {
        this.handleNotification("Something went wrong while deleting channel!", true)
      })
  }

  updateCategory() {
    if (this.categoryIdToUpdate) {
      Swal.showLoading();
      if (this.categoryIdToUpdate) {
        this.operationService.updateCategory(this.categoryIdToUpdate, this.categoryForm.value)
          .subscribe(
            response => {
              Swal.close();
              if (response.success) {
                Swal.fire({
                  icon: 'success',
                  title: "Category updated successfully!"
                }).then(() => {
                  this.categoryIdToUpdate = null;
                  this.listAllCategories();
                  this.updating = false;
                  this.categoryForm.reset();
                })
              } else {
                this.handleNotification(response.error_message || "Problem in updating category!", true)
              }
            },
            error => {
              Swal.close();
              this.handleNotification(error.error.error_message || "Problem in updating category!", true);
            }
          )
      }
    }
  }

  addCategory() { 
    if (this.categoryForm.invalid) {
      for (var i in this.categoryForm.controls) {
        this.categoryForm.controls[i].markAsDirty();
        this.categoryForm.controls[i].updateValueAndValidity();
      }
    } else {
      Swal.showLoading();
      this.operationService.addCategory(this.categoryForm.value)
        .subscribe(
          response => {
            Swal.close();
            if (response.success) {
              Swal.fire({
                icon: 'success',
                title: "Category added successfully!"
              }).then(() => {
                this.listAllCategories();
                this.updating = false;
                this.categoryForm.reset();
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: response.error_message || "Problem in adding Category!"
              });
            }
          },
          error => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: error.error.error_message || "Problem in adding Category!"
            });
          }
        )
    }
  }
  searchText(searchText: any) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search(searchText.data).draw();
    });
  }

  toggleUpdateMode() {
    this.updating = false;
    this.categoryForm.reset();
  }

  // helper functions

  fetchCategory(category) {

    this.updating = true;
    this.categoryIdToUpdate = category.id || null;
    this.categoryForm = this.fb.group({
      name: [category.name, [Validators.required]]
    });
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }
}
