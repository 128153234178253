import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { MerchOperationService } from '../../_services/merch-operation.service';
import { ActivatedRoute, Router } from "@angular/router";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
interface other {
  dateAfter?: string;
  dateBefore?: string;
  queryAgentUser?: string
}
@Component({
  selector: 'app-merch-audits',
  templateUrl: './merch-audits.component.html',
  styleUrls: ['./merch-audits.component.scss']
})
export class MerchAuditsComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject();

  public previousUrl: string;
  public nextUrl: string;

  public queryAgentUser: string;

  public gLimit = 10;
  public gOffset = 0;
  // public count: number;


  cities: Array<any> = [];

  isSelectAllChecked = false;

  public auditData: any;
  accessToken: string;

  dashboardForm: FormGroup;

  dateStart: string;
  date_range: any;
  format: 'MM/DD/YYYY';
  filterValue: string;
  alwaysShowCalendars: boolean;
  // minDate = moment('2018-01-01');
  minDate = moment();
  maxDate = moment().add(0, 'days');
  showRangeLabelOnInput = true;
  public dateAfter: any;
  public dateBefore: any;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')]
  };

  dropdownMultiselectSettings: IDropdownSettings;
  selected = { start: moment().toDate(), end: moment().toDate() };
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private merchOperationService: MerchOperationService
  ) { }

  ngOnInit(): void {

    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      stateSave: true,
      stateSaveCallback(settings, data) {
        settings._iDisplayLength != that.gLimit ? that.changePageLength(settings._iDisplayLength) : '';
        localStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data));
      },
      stateLoadCallback(settings) {
        return JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance));
      },
      dom: '<"top"<"center-col"l><"right-col">>rt',
      destroy: true
    };

    this.dashboardForm = this.fb.group({
      // date_range: [{ date_after: moment('2018-01-01'), date_before: moment('2018-03-30') }], // default This Month
      date_range: []
      // date_range: [{ date_after: moment().toDate(), date_before: moment().endOf('month').toDate() }], // default This Month
    });
    this.queryAgentUser = this.route.snapshot.queryParams['agent_user'] || null;


    this.dropdownMultiselectSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: false
    };

    this.viewMerchAuditData(this.gLimit, this.gOffset, { queryAgentUser: this.queryAgentUser });
    this.accessToken = localStorage.getItem('accessToken');
  }


  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  applyDateFilter() {
    console.log('apply date-------------------')
    this.dateAfter = `${this.dashboardForm.value.date_range.date_after.year()}-${this.dashboardForm.value.date_range.date_after.month() + 1}-${this.dashboardForm.value.date_range.date_after.date()}`;
    this.dateBefore = `${this.dashboardForm.value.date_range.date_before.year()}-${this.dashboardForm.value.date_range.date_before.month() + 1}-${this.dashboardForm.value.date_range.date_before.date()}`;
    this.viewMerchAuditData(this.gLimit, this.gOffset, { dateBefore: this.dateBefore, dateAfter: this.dateAfter });

  }


  searchText(searchText: any) {
    //   Swal.showLoading();
    //   this.snapDashboardService.searchPMSnapData(searchText?.data)
    //     .subscribe(res => {
    //       Swal.close();
    //       this.snapData = [];
    //       if (res && res.success) {
    //         this.snapData = res && res.data ? res.data.results : [];
    //         this.isSelectAllChecked = false;
    //         this.snapData.map(snapDatum => snapDatum.isChecked = false);
    //
    //         this.previousUrl = res && res.data ? res.data.previous : null;
    //         this.nextUrl = res && res.data ? res.data.next : null;
    //
    //         // this.count = res && res.data ? res.data.count : 0;
    //
    //         this.rerender();
    //       } else {
    //         Swal.fire({
    //           icon: 'error',
    //           title: res.error.error_message,
    //           timer: 1500
    //         });
    //       }
    //     }, error => {
    //       Swal.close();
    //       Swal.fire({
    //         icon: 'error',
    //         title: error.error_message || 'Something went wrong in fetching snap data!',
    //         timer: 1500
    //       });
    //     });
  }

  // PM Methods

  viewMerchAuditData(limit?: number, offset?: number, other?: other) {
    Swal.showLoading();
    this.merchOperationService.getMerchAudits(limit, offset, other)
      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.auditData = res && res.data ? res.data.results : [];
          this.isSelectAllChecked = false;
          this.auditData.map(auditDatum => auditDatum.isChecked = false);

          this.previousUrl = res && res.data ? res.data.previous : null;
          this.nextUrl = res && res.data ? res.data.next : null;

          // this.count = res && res.data ? res.data.count : 0;

          this.rerender();
        } else {
          this.auditData = [];
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Something went wrong in fetching audit data!',
          timer: 1500
        });
      });
  }

  exportData() {
    let extra = '';

    if (this.dateAfter || this.dateBefore) {
      extra = `?${this.dateAfter ? `date_after=${this.dateAfter}` : ''}${this.dateAfter && this.dateBefore ? '&' : ''}${this.dateBefore ? `date_before=${this.dateBefore}` : ''}`;
    }

    Swal.showLoading();
    this.http.get(`${environment.baseUrl}/merch/submit-survey/export${extra}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
      headers: new HttpHeaders()
        .set('Authorization', this.accessToken)
    })
      .subscribe(
        (response: HttpResponse<Blob>) => {
          Swal.close();
          console.log(response.headers);
          const filename = 'merch_audit.csv';
          const binaryData = [];
          binaryData.push(response.body);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        },
        error => {
          Swal.close();
          console.log(error);
        }
      );
  }

  // helper methods

  changePageLength(limit: number) {
    this.gLimit = limit;
    this.viewMerchAuditData(this.gLimit, this.gOffset, { queryAgentUser: this.queryAgentUser });
  }

  handlePagination(paginationString: string) {

    Swal.showLoading();

    if (paginationString == 'next') {
      this.http.get<any>(this.nextUrl)
        .subscribe(response => {
          Swal.close();
          this.auditData = response && response.data ? response.data.results : [];
          this.auditData.map(snapDatum => snapDatum.isChecked = false);

          this.previousUrl = response && response.data ? response.data.previous : null;
          this.nextUrl = response && response.data ? response.data.next : null;

          const { limit, offset } = this.extractQueryParams(this.nextUrl);
          this.gOffset = parseInt(offset || '0') - parseInt(limit || '0');

          this.rerender();
        }, error => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: error.error_message || 'Something went wrong in fetching snap data!',
            timer: 1500
          });
        });
    }

    if (paginationString == 'previous') {
      this.http.get<any>(this.previousUrl)
        .subscribe(response => {
          Swal.close();
          this.auditData = response && response.data ? response.data.results : [];
          this.auditData.map(snapDatum => snapDatum.isChecked = false);

          this.previousUrl = response && response.data ? response.data.previous : null;
          this.nextUrl = response && response.data ? response.data.next : null;

          const { limit, offset } = this.extractQueryParams(this.nextUrl);
          this.gOffset = parseInt(offset || '0') - parseInt(limit || '0');

          this.rerender();
        }, error => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: error.error_message || 'Something went wrong in fetching snap data!',
            timer: 1500
          });
        });
    }
  }

  selectAll() {
    this.isSelectAllChecked = !this.isSelectAllChecked;

    if (this.isSelectAllChecked) {
      this.auditData.map(snapDatum => snapDatum.isChecked = true);
    } else {
      this.auditData.map(snapDatum => snapDatum.isChecked = false);
    }
  }

  callservice(id, index, array) {
    return this.merchOperationService.removeAudit(id, this.accessToken)
      .then(res => {
        // Check if it's the last iteration
        if (index === array.length - 1) {
          // Show success Swal after the loop is done
          Swal.fire({
            icon: 'success',
            title: 'Records deleted successfully!'
          }).then(() => {
            this.viewMerchAuditData()
          });
        }
      })
      .catch(error => {
        // Show error Swal if something goes wrong in the loop
        Swal.fire({
          icon: 'error',
          titleText: `Error deleting record with ID ${id}`
        })
      });
  }

  deleteAuditData() {
    const recordsToBeDeleted = this.auditData.filter(snapDatum => snapDatum.isChecked == true);
    // Show the warning Swal once before the loop
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to delete?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Promise.all(recordsToBeDeleted.map((record, index, array) => {
          return this.callservice(record.id, index, array);
        }));
      }
    });
  }

  rowSelected(id) {
    this.auditData.map(data => {
      if (data.id == id) {
        data.isChecked = !data.isChecked;
      }
    });
  }


  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

  generateRandomId() {
    let randomNumber = Math.floor(Math.random() * 1000);
    let randomId = 'S' + randomNumber.toString().padStart(3, '0') + 'J';
    return randomId;
  }
  generateRandomAId() {
    let randomNumber = Math.floor(Math.random() * 1000);
    let randomId = 'A' + randomNumber.toString().padStart(3, '0') + 'J';
    return randomId;
  }
  generateRandomNumber() {
    let randomNumber = Math.floor(Math.random() * 10) + 1;
    return randomNumber;
  }
  generateRandomNumber2() {
    let randomNumber = Math.floor(Math.random() * 11) + 10;
    return randomNumber;
  }

  generateRandomDate() {
    let start = new Date(2023, 0, 1); // January 1st, 2023
    let end = new Date(2023, 11, 31); // December 31st, 2023
    let randomDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    return randomDate.toLocaleDateString();
  }

  extractQueryParams(url: string): { limit: string, offset: string } {

    const queryParamsString = url.split('?')[1];

    const limitString = queryParamsString.split('&')[0];
    const limit = limitString.split('=')[1];

    const offsetString = queryParamsString.split('&')[1];
    const offset = offsetString.split('=')[1] || '0';

    return {
      limit,
      offset
    };
  }
}
