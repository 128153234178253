import { FormGroup, FormBuilder, Validators, } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/_services/dashboard.service';
import { AuthService, LocalizeService } from 'src/app/_services';
import { DataTableDirective } from 'angular-datatables';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MerchOperationService } from '../../_services/merch-operation.service';
import { MerchDashboardService } from 'src/app/_services/merch-dashboard.service';
import * as XLSX from 'xlsx';
import { ExcelData } from 'src/app/dashboard/dashboard.component';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-merch-filter',
  templateUrl: './merch-filter.component.html',
  styleUrls: ['./merch-filter.component.scss']
})
export class MerchFilterComponent implements OnInit, OnChanges {

  merchData: any;
  combinedReportData: any
  // Price monitor
  priceMaxDataArray = [];
  priceMinDataArray = [];
  priceMeanDataArray = [];
  priceModeDataArray = [];
  priceMinMaxBarChartExcelData: ExcelData = { labels: [], data: [] };
  public priceMinMaxBarChartData: ChartDataSets[] = [];
  public priceMinMaxBarChartLabels: Label[] = [];

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject();
  public previousUrl: string;
  public nextUrl: string;

  public gLimit = 10;
  public gOffset = 0;

  @Output() filterAppliedEvent = new EventEmitter<any>();
  @Output() qnnTypeChange = new EventEmitter<any>();
  @Output() resetFilter = new EventEmitter<any>();
  @Output() scrollTo = new EventEmitter<number>();

  @Input() availability: any;
  @Input() outOfStock: any;
  @Input() selfShare: any;
  @Input() selfBalance: any;
  @Input() secondaryDisplay: any;
  @Input() storebalance: any;
  @Input() pricemeasure: any;
  @Input() promotion: any;

  faEllipsisV = faEllipsisV;
  overviewData: any;

  filter_obj: any = [];
  months: Array<any> = [];
  regions: Array<any> = [];
  branchName: Array<any> = [];
  branchNo: Array<any> = [];
  gov: Array<any> = [];
  govIds: Array<any> = [];
  subCategories: Array<any> = [];
  countries: Array<any> = [];

  cities: Array<any> = [];
  cityIds: Array<any> = [];

  stores: Array<any> = [];
  storeIds: Array<any> = [];

  branchs: Array<any> = [];
  branchIds: Array<any> = [];

  branchNos: Array<any> = [];
  branchNoIds: Array<any> = [];

  storeTypes: Array<any> = [];
  storeTypeIds: Array<any> = [];

  regionIds: Array<number> = [];
  monthIds: Array<number> = [];
  channels: Array<any> = [];
  retailers: Array<any> = [];

  categories: Array<any> = [];
  categoryIds: Array<number> = [];

  subcategories: Array<any> = [];
  subcategoryIds: Array<number> = [];

  brands: Array<any> = [];
  brandIds: Array<number> = [];

  skus: Array<any> = [];
  skuIds: Array<any> = [];

  filterString: string;
  dropdownSettings: IDropdownSettings;
  datedropdownSettings: IDropdownSettings;
  dropdownMultiselectSettings: IDropdownSettings;

  dashboardForm: FormGroup;

  csvreportData: any = [];
  allChanges: any[] = []
  isAdmin: boolean;
  // calenders varaibles

  dateRange: any;
  alwaysShowCalendars: boolean;
  // minDate = moment('2018-01-01');
  minDate = moment().subtract(3, 'years');
  maxDate = moment().add(0, 'days');
  showRangeLabelOnInput = true;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'Select month': [moment().startOf('month'), moment().endOf('month')]
  };

  accessToken: string;


  constructor(
    private dashboardService: DashboardService,
    private merchdashboard: MerchDashboardService,
    private fb: FormBuilder,
    private localizeService: LocalizeService,
    private merchOperationService: MerchOperationService,
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.alwaysShowCalendars = true;
  }
  /**
   * @returns void
   * ! as of now setting default filter is by id which may changes during production
   * TODO: set default filters -> 'This Month' & 'Price Monitor' during ngOnInit and resetFilters
   */
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      stateSave: true
    };
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.datedropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };

    this.dropdownMultiselectSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: false
    };

    this.dashboardForm = this.fb.group({
      date_range: [{ date_after: moment().startOf('month'), date_before: moment().endOf('month') }], // default This Month
      region: [''],
      governorate: [''],
      city: [''],
      store: [''],
      branch: [''],
      branchno: [''],
      store_type: [''],
      channel: [''],
      retailer: [''],
      category: [''],
      subcategory: [''],
      brand: [''],
      sku: [''],
      month: [''],
    });

    const accessToken = localStorage.getItem('accessToken');
    if (this.authService.getCurrentUserRole(accessToken) === 'Admin') {
      this.isAdmin = true;
    }

    this.accessToken = localStorage.getItem('accessToken');
    this.getFilterString();
    this.getRegionList();
    this.getCategoryList();
  }

  // main methods
  ngOnChanges(changes: SimpleChanges) {
    console.info("changes detected");


    for (const propName in changes) {
      if (changes.hasOwnProperty(propName) && !changes[propName].firstChange) {
        this.allChanges.push({ [propName]: changes[propName].currentValue });
      }
    }

    console.info(this.allChanges);
  }
  scrollToItem(targetIndex: number): void {
    this.scrollTo.emit(targetIndex);
  }
  onFilterSubmit() {
    if (this.dashboardForm.invalid) {
      for (const i in this.dashboardForm.controls) {
        this.dashboardForm.controls[i].markAsDirty();
        this.dashboardForm.controls[i].updateValueAndValidity();
      }
    } else {
      const filterDataObj = { ...this.dashboardForm.value };
      this.filterString = '?';
      for (const filterDataKey in filterDataObj) {

        // if it's date_range, format it
        if (filterDataKey === 'date_range') {
          const dateRange = filterDataObj?.date_range || null;
          if (dateRange) {
            const fromDate: Moment = this.dashboardForm.value?.date_range?.date_after;
            const tillDate: Moment = this.dashboardForm.value?.date_range?.date_before;
            filterDataObj.date_after = this.customDateFormat(fromDate, 'YYYY-MM-DD');
            filterDataObj.date_before = this.customDateFormat(tillDate, 'YYYY-MM-DD');
          }
        }
        delete filterDataObj.date_range;

        // don't to anything for questionnaire type
        // for all other filters we extract ids
        if (filterDataKey !== 'questionnaire_type') {
          const otherFormValues = (filterDataObj[filterDataKey]) as Array<any>;
          if (otherFormValues) {
            filterDataObj[filterDataKey] = otherFormValues.map(eachValue => eachValue.id);
          }
        }
      }

      for (const filter in filterDataObj) {
        if (filter == 'questionnaire_type' || filter == 'date_after' || filter == 'date_before') {
          this.filterString = this.filterString + `${filter}=${filterDataObj[filter]}&`;
        } else {
          if (filterDataObj[filter]) {
            this.filterString = this.filterString + `${filter}=${filterDataObj[filter].join(',')}&`;
          }
        }
      }

      // emit filter string here
      this.filterAppliedEvent.emit(this.filterString);
    }
  }

  getarray(queryString: string): any {
    const params = new URLSearchParams(queryString);
    const jsonParams: any = {};

    // Map each parameter to its corresponding key in the JSON object
    params.forEach((value, key) => {
      // Convert comma-separated values to arrays
      jsonParams[key] = value.split(',').map(item => isNaN(Number(item)) ? item : Number(item));
    });

    // add _ids in end of all keys except date_before and date_after
    const jsonParamsWithIds = {};
    Object.keys(jsonParams).forEach(key => {
      if (key !== 'date_before' && key !== 'date_after') {
        // Ensure the value is always an array
        const values = Array.isArray(jsonParams[key]) ? jsonParams[key] : [jsonParams[key]];
        jsonParamsWithIds[key + '_ids'] = values;
      } else {
        jsonParamsWithIds[key] = jsonParams[key];
      }
    });

    // Convert string "0" to numeric 0
    if (jsonParamsWithIds['date_before'] === '0') {
      jsonParamsWithIds['date_before'] = 0;
    }

    if (jsonParamsWithIds['date_after'] === '0') {
      jsonParamsWithIds['date_after'] = 0;
    }

    //dearray date_before and date_after
    if (jsonParamsWithIds['date_before']) {
      jsonParamsWithIds['date_before'] = jsonParamsWithIds['date_before'][0];
    }

    if (jsonParamsWithIds['date_after']) {
      jsonParamsWithIds['date_after'] = jsonParamsWithIds['date_after'][0];
    }

    return jsonParamsWithIds;
  }

  csvreport() {
    this.merchdashboard.csvReport(this.getarray(this.filterString)).subscribe(
      response1 => {
        if (response1 && response1.success) {
          this.csvreportData = response1.data;

          // Extract SKU IDs from the CSV report data
          const skuIds = this.csvreportData.flatMap(item => item.skus.map(sku => sku.sku_id));

          // Modify the filter string to only include date_after, date_before and add sku_ids
          // Now call the second report function with the modified filter string
          this.priceMinMaxReport(this.filterString, this.csvreportData);
        } else {
          this.handleNotification('Error generating CSV!', true);
        }
      },
      error => {
        Swal.close();
        this.handleNotification('Error generating CSV!', true);
      }
    );
  }

  priceMinMaxReport(filterString?, csvreportData?: any) {
    Swal.fire({
      title: "Calculating price measure..",
      didOpen: () => {
        Swal.showLoading()
      }
    })
    const arr = this.getarray(filterString)
    const newarr = delete arr.branchno_ids
    this.merchdashboard.priceMinMaxReport(arr).subscribe(
      response2 => {
        if (response2 && response2.success) {
          const priceMinMaxData = response2.data ? [...response2.data] : [];

          // Create a map for quick lookup of price report data by SKU
          const priceDataMap = priceMinMaxData.reduce((map, item) => {
            map[item.sku] = item;
            return map;
          }, {});

          // Merge the reports
          const combinedReport = csvreportData.map(item => {
            return {
              ...item,
              skus: item.skus.map(skuItem => {
                return {
                  ...skuItem,
                  priceData: priceDataMap[skuItem.sku] || {}
                };
              })
            };
          });

          console.info(combinedReport);
          Swal.close()
          this.combinedReportData = combinedReport;
          // Trigger any necessary updates with the combined data
          this.dtTrigger.next(combinedReport);
        } else {
          this.handleNotification('Error generating price report!', true);
        }
      },
      error => {
        Swal.close();
        this.handleNotification('Error generating price report!', true);
      }
    );
  }


  exportTable() {
    const formattedData = [];

    console.log('combined data', this.combinedReportData)
    this.combinedReportData.forEach(item => {
      item.skus.forEach(sku => {
        const row = {
          Name: item.name,
          Availability: item.availability,
          'Out of Stock': item.out_of_stock,
          'Shelf Balance': item.shelf_balance,
          'Shelf Share': item.shelf_share,
          SKU: sku.sku,
          'Max Price': sku.priceData.max,
          'Mean Price': sku.priceData.mean,
          'Min Price': sku.priceData.min,
          'Mode Price': sku.priceData.mode
        };

        formattedData.push(row);
      });
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(formattedData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'overview.xlsx');
    // You can now use 'formattedData' for further processing or export
  }

  modifyFilterString(filterString, skuIds) {
    const params = new URLSearchParams(filterString);
    const newParams = new URLSearchParams();

    // Keep only date_after and date_before
    if (params.has('date_after')) {
      newParams.append('date_after', params.get('date_after'));
    }
    if (params.has('date_before')) {
      newParams.append('date_before', params.get('date_before'));
    }

    // Add sku_ids
    newParams.append('sku', skuIds);

    return newParams.toString();
  }



  loadOverviewData() {
    Swal.showLoading();

    this.dashboardService.getOverview()
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            this.overviewData = response.data;
          } else {
            this.handleNotification(response.error_message || 'Problem in fetching overview data!', true);
          }
        },
        error => {
          Swal.close();
          this.handleNotification(error.error.error_message || 'Problem in fetching overview data!', true);
        });
  }
  datesUpdated(event) {
    this.brands = [];
    this.skus = [];
    this.cities = [];
    this.countries = [];
    this.channels = [];
    this.dashboardForm.controls.country.setValue([]);
    this.dashboardForm.controls.channel.setValue([]);
    this.dashboardForm.controls.category.setValue([]);
    this.dashboardForm.controls.brand.setValue([]);
    this.dashboardForm.controls.sku.setValue([]);
    this.getFilterString();
  }
  resetFilters() {
    this.govIds = [];
    this.regionIds = [];
    this.cityIds = [];
    this.branchIds = [];
    this.storeIds = [];
    this.branchNoIds = [];
    this.storeTypeIds = [];
    this.categoryIds = [];
    this.subcategoryIds = [];
    this.brandIds = [];
    this.skus = [];

    this.dashboardForm.controls.region.setValue(null);
    this.dashboardForm.controls.governorate.setValue(null);
    this.dashboardForm.controls.city.setValue(null);
    this.dashboardForm.controls.store.setValue(null);
    this.dashboardForm.controls.branch.setValue(null);
    this.dashboardForm.controls.branchno.setValue(null);
    this.dashboardForm.controls.store_type.setValue(null);
    this.dashboardForm.controls.category.setValue(null);
    this.dashboardForm.controls.subcategory.setValue(null);
    this.dashboardForm.controls.brand.setValue(null);
    this.dashboardForm.controls.sku.setValue(null);
    this.getFilterString();
  }

  // helper methods

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

  async onMonthSelect(event?) {
    Swal.showLoading();
    if (event) {
      this.monthIds.push(event.id);

      this.dashboardForm.controls.category.setValue(null);
      this.dashboardForm.controls.brand.setValue(null);
      this.dashboardForm.controls.sku.setValue(null);
      this.getFilterString();
      this.getCategoryList();
    }

    this.countries = [];
    Swal.close();
  }
  async onMonthDeSelect(event) {
    const monthIdToRemove = this.monthIds.indexOf(event.id);
    if (monthIdToRemove !== -1) {
      this.monthIds.splice(monthIdToRemove, 1);
    }
    if (this.monthIds.length == 0) {

      this.dashboardForm.controls.category.setValue(null);
      this.dashboardForm.controls.brand.setValue(null);
      this.dashboardForm.controls.sku.setValue(null);
      this.cities = [];
      this.channels = [];
      this.brands = [];
      this.skus = [];
      const element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
      element.click();
    }
    else {
      this.getFilterString();
      this.getCategoryList();
    }

  }
  async onMonthSelectAll(event?) {
    console.log(event, 'event');
    Swal.showLoading();
    if (event) {
      this.monthIds = event.map(month => month.id);
      setTimeout(() => {
        this.getFilterString();
        this.getCategoryList();
      }, 500);
    }

    Swal.close();
  }
  async onMonthDeSelectAll(event) {
    this.monthIds = [];

    this.dashboardForm.controls.category.setValue(null);
    this.dashboardForm.controls.brand.setValue(null);
    this.dashboardForm.controls.sku.setValue(null);
    this.cities = [];
    this.channels = [];
    this.brands = [];
    this.skus = [];
    const element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

  }

  // regions
  async onRegionSelect(event?) {
    Swal.showLoading();
    if (event) {
      this.regionIds.push(event.id);
      this.dashboardForm.controls.governorate.setValue(null);
      this.dashboardForm.controls.city.setValue(null);
      this.dashboardForm.controls.store.setValue(null);
      this.dashboardForm.controls.branch.setValue(null);
      this.dashboardForm.controls.branchno.setValue(null);
      this.dashboardForm.controls.store_type.setValue(null);


      this.govIds = [];
      this.cityIds = [];
      this.branchIds = [];
      this.storeIds = [];
      this.branchNoIds = [];
      this.storeTypeIds = [];

      setTimeout(() => {
        this.getFilterString();
        this.getGovernorateList();
      }, 500);

    }
    Swal.close();
  }
  async onRegionDeSelect(event) {
    const regionIdToRemove = this.regionIds.indexOf(event.id);
    if (regionIdToRemove !== -1) {
      this.regionIds.splice(regionIdToRemove, 1);
    }
    this.dashboardForm.controls.governorate.setValue(null);
    this.dashboardForm.controls.city.setValue(null);
    this.dashboardForm.controls.store.setValue(null);
    this.dashboardForm.controls.branch.setValue(null);
    this.dashboardForm.controls.branchno.setValue(null);
    this.dashboardForm.controls.store_type.setValue(null);


    this.govIds = [];
    this.cityIds = [];
    this.branchIds = [];
    this.storeIds = [];
    this.branchNoIds = [];
    this.storeTypeIds = [];
    setTimeout(() => {
      this.getFilterString();
      this.getGovernorateList();
    }, 500);

  }
  async onRegionSelectAll(event?) {
    console.log(event, 'event');
    Swal.showLoading();
    if (event) {
      this.dashboardForm.controls.governorate.setValue(null);
      this.dashboardForm.controls.city.setValue(null);
      this.dashboardForm.controls.store.setValue(null);
      this.dashboardForm.controls.branch.setValue(null);
      this.dashboardForm.controls.branchno.setValue(null);
      this.dashboardForm.controls.store_type.setValue(null);

      this.govIds = [];
      this.cityIds = [];
      this.branchIds = [];
      this.storeIds = [];
      this.branchNoIds = [];
      this.storeTypeIds = [];
      this.regionIds = event.map(region => region.id);
      setTimeout(() => {
        this.getFilterString();
        this.getGovernorateList();
      }, 500);
    }
    Swal.close();
  }
  async onRegionDeSelectAll(event) {
    this.dashboardForm.controls.governorate.setValue(null);
    this.dashboardForm.controls.city.setValue(null);
    this.dashboardForm.controls.store.setValue(null);
    this.dashboardForm.controls.branch.setValue(null);
    this.dashboardForm.controls.branchno.setValue(null);
    this.dashboardForm.controls.store_type.setValue(null);

    this.gov = []
    this.cities = []
    this.storeTypes = []
    this.stores = []
    this.branchNos = []
    this.branchs = []
    this.govIds = [];
    this.regionIds = [];
    this.cityIds = [];
    this.branchIds = [];
    this.storeIds = [];
    this.branchNoIds = [];
    this.storeTypeIds = [];
    const element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

  }


  // governorate
  async onGovernorateSelect(event?) {
    Swal.showLoading();
    if (event) {
      this.dashboardForm.controls.city.setValue(null);
      this.dashboardForm.controls.store.setValue(null);
      this.dashboardForm.controls.branch.setValue(null);
      this.dashboardForm.controls.branchno.setValue(null);
      this.dashboardForm.controls.store_type.setValue(null);


      this.cityIds = [];
      this.branchIds = [];
      this.storeIds = [];
      this.branchNoIds = [];
      this.storeTypeIds = [];
      this.govIds.push(event.id);
      setTimeout(() => {
        this.getFilterString();
        this.getCityList();
      }, 500);

    }
    Swal.close();
  }
  async onGovernorateDeSelect(event) {
    const govIdToRemove = this.govIds.indexOf(event.id);
    if (govIdToRemove !== -1) {
      this.govIds.splice(govIdToRemove, 1);
    }
    this.dashboardForm.controls.city.setValue(null);
    this.dashboardForm.controls.store.setValue(null);
    this.dashboardForm.controls.branch.setValue(null);
    this.dashboardForm.controls.branchno.setValue(null);
    this.dashboardForm.controls.store_type.setValue(null);

    this.cityIds = [];
    this.branchIds = [];
    this.storeIds = [];
    this.branchNoIds = [];
    this.storeTypeIds = [];

    setTimeout(() => {
      this.getFilterString();
      this.getGovernorateList();
    }, 500);

  }
  async onGovernorateSelectAll(event?) {
    Swal.showLoading();
    if (event) {
      this.dashboardForm.controls.city.setValue(null);
      this.dashboardForm.controls.store.setValue(null);
      this.dashboardForm.controls.branch.setValue(null);
      this.dashboardForm.controls.branchno.setValue(null);
      this.dashboardForm.controls.store_type.setValue(null);

      this.cities = []
      this.storeTypes = []
      this.stores = []
      this.branchNos = []
      this.branchs = []
      this.cityIds = [];
      this.branchIds = [];
      this.storeIds = [];
      this.branchNoIds = [];
      this.storeTypeIds = [];
      this.govIds = event.map(region => region.id);
      setTimeout(() => {
        this.getFilterString();
        this.getCityList();
      }, 500);
    }
    Swal.close();
  }

  async onGovernorateDeSelectAll(event) {
    this.dashboardForm.controls.city.setValue(null);
    this.dashboardForm.controls.store.setValue(null);
    this.dashboardForm.controls.branch.setValue(null);
    this.dashboardForm.controls.branchno.setValue(null);
    this.dashboardForm.controls.store_type.setValue(null);


    this.cityIds = [];
    this.govIds = [];
    this.branchIds = [];
    this.storeIds = [];
    this.branchNoIds = [];
    this.storeTypeIds = [];
    const element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

  }
  // city
  async onCitySelect(event?) {
    Swal.showLoading();
    if (event) {
      this.dashboardForm.controls.store.setValue(null);
      this.dashboardForm.controls.branchno.setValue(null);
      this.dashboardForm.controls.store_type.setValue(null);

      this.storeIds = [];
      this.branchNoIds = [];
      this.storeTypeIds = [];
      this.cityIds.push(event.id);
      setTimeout(() => {
        this.getFilterString();
        this.getStoreTypeList();
      }, 500);

    }
    Swal.close();
  }
  async onCityDeSelect(event) {
    const cityIdToRemove = this.cityIds.indexOf(event.id);
    if (cityIdToRemove !== -1) {
      this.cityIds.splice(cityIdToRemove, 1);
    }
    this.dashboardForm.controls.store.setValue(null);
    this.dashboardForm.controls.branchno.setValue(null);
    this.dashboardForm.controls.store_type.setValue(null);


    this.storeIds = [];
    this.branchNoIds = [];
    this.storeTypeIds = [];
    setTimeout(() => {
      this.getFilterString();
      this.getStoreTypeList();
    }, 500);

  }
  async onCitySelectAll(event?) {
    console.log(event, 'event');
    Swal.showLoading();
    if (event) {

      this.cityIds = event.map(region => region.id);
      setTimeout(() => {
        this.getFilterString();
        this.getStoreTypeList();
      }, 500);
    }
    Swal.close();
  }

  async onCityDeSelectAll(event) {
    this.dashboardForm.controls.store.setValue(null);
    this.dashboardForm.controls.branchno.setValue(null);
    this.dashboardForm.controls.store_type.setValue(null);

    this.storeTypes = []
    this.stores = []
    this.branchNos = []
    this.branchs = []
    this.storeIds = [];
    this.branchNoIds = [];
    this.storeTypeIds = [];
    const element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

  }

  // store
  async onStoreSelect(event?) {
    Swal.showLoading();
    if (event) {
      this.storeIds.push(event.id);
      this.dashboardForm.controls.branchno.setValue(null);

      this.branchNoIds = [];

      setTimeout(() => {
        this.getFilterString();
        this.getBranchList()
      }, 500);
    }
    Swal.close();
  }

  async onStoreDeSelect(event) {
    const idToRemove = this.storeIds.indexOf(event.id);
    if (idToRemove !== -1) {
      this.storeIds.splice(idToRemove, 1);
    }
    this.dashboardForm.controls.branchno.setValue(null);

    this.branchNoIds = [];

    this.dashboardForm.controls.branch.setValue(null);

    this.branchIds = [];

    setTimeout(() => {
      this.getFilterString();
      this.getBranchList()

    }, 500);
  }

  async onStoreSelectAll(event?) {
    console.log(event, 'event');
    Swal.showLoading();
    if (event) {
      this.storeIds = event.map(ev => ev.id);
      this.dashboardForm.controls.branchno.setValue(null);

      this.branchNoIds = [];

      setTimeout(() => {
        this.getFilterString();
        this.getBranchList()
      }, 500);
    }
    Swal.close();
  }

  async onStoreDeSelectAll(event) {
    const element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

    this.branchNoIds = []
    this.branchs = []
  }

  // Branch
  async onBranchSelect(event?) {
    Swal.showLoading();
    if (event) {
      this.dashboardForm.controls.branchno.setValue(null);

      this.branchNoIds = [];
      this.branchIds.push(event.id);
      setTimeout(() => {
        this.getFilterString();
        this.getBanchNoList();
      }, 500);

    }
    Swal.close();
  }
  async onBranchDeSelect(event) {
    const idToRemove = this.branchIds.indexOf(event.id);
    if (idToRemove !== -1) {
      this.branchIds.splice(idToRemove, 1);
    }
    this.dashboardForm.controls.branchno.setValue(null);
    this.branchNoIds = [];
    setTimeout(() => {
      this.getFilterString();
      this.getBanchNoList();
    }, 500);

  }
  async onBranchSelectAll(event?) {
    Swal.showLoading();
    if (event) {
      this.dashboardForm.controls.branchno.setValue(null);

      this.branchNoIds = [];
      this.branchIds = event.map(item => item.id);
      setTimeout(() => {
        this.getFilterString();
        this.getBanchNoList();
      }, 500);
    }
    Swal.close();
  }
  async onBranchDeSelectAll(event) {
    this.dashboardForm.controls.branchno.setValue(null);

    this.branchNos = []

    this.branchIds = [];
    this.branchNoIds = [];
    const element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

  }

  // Branch No
  async onBranchNoSelect(event?) {
    Swal.showLoading();
    if (event) {
      this.branchNoIds.push(event.id);
      setTimeout(() => {
        this.getFilterString();
        this.getStoreTypeList();
      }, 500);

    }
    Swal.close();
  }

  async onBranchNoDeSelect(event) {
    const idToRemove = this.branchNoIds.indexOf(event.id);
    if (this.branchNoIds.length !== -1) {
      this.branchIds.splice(idToRemove, 1);
    }

    setTimeout(() => {
      this.getFilterString();
      // this.getStoreList();
    }, 500);

  }
  async onBranchNoSelectAll(event?) {
    console.log(event, 'event');
    Swal.showLoading();
    if (event) {
      this.branchIds = event.map(item => item.id);
      setTimeout(() => {
        this.getFilterString();
        this.getStoreTypeList();
      }, 500);
    }
    Swal.close();
  }
  async onBranchNoDeSelectAll(event) {

    this.branchNoIds = [];
    const element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

  }



  // Store types
  async onStoreTypeSelect(event?) {
    console.log('on store clicked');
    Swal.showLoading();
    if (event) {
      this.storeIds.push(event.id);
      setTimeout(() => {
        this.getFilterString();
        this.getStoreList()
      }, 500);

    }
    Swal.close();
  }
  async onStoreTypeDeSelect(event) {
    const idToRemove = this.storeIds.indexOf(event.id);
    if (this.storeIds.length !== -1) {
      this.storeIds.splice(idToRemove, 1);
    }
    if (this.storeTypes.length === 0) {
      const element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
      element.click();
    }
    else {
      setTimeout(() => {
        this.getFilterString();
        this.getStoreList();
      }, 500);
    }

  }
  async onStoreTypeSelectAll(event?) {
    console.log(event, 'event');
    Swal.showLoading();
    if (event) {
      this.storeIds = event.map(item => item.id);
      setTimeout(() => {
        this.getFilterString();
        this.getStoreList()
      }, 500);
    }
    Swal.close();
  }
  async onStoreTypeDeSelectAll(event) {
    this.storeTypes = [];
    this.branchNos = []
    this.branchs = []

    const element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

  }

  // category
  async onCategorySelect(event?) {
    Swal.showLoading();
    if (event) {
      this.dashboardForm.controls.subcategory.setValue(null);
      this.dashboardForm.controls.brand.setValue(null);
      this.dashboardForm.controls.sku.setValue(null);
      this.subcategoryIds = [];
      this.brandIds = [];
      this.skus = [];
      this.categoryIds.push(event.id);
      this.getFilterString();
      this.getSubcategoryList();
    }
    Swal.close();
  }
  async onCategoryDeSelect(event) {
    const categoryIdToRemove = this.categoryIds.indexOf(event.id);
    if (categoryIdToRemove !== -1) {
      this.categoryIds.splice(categoryIdToRemove, 1);
    }
    this.dashboardForm.controls.subcategory.setValue(null);
    this.dashboardForm.controls.brand.setValue(null);
    this.dashboardForm.controls.sku.setValue(null);
    this.subcategoryIds = [];
    this.brandIds = [];
    this.skus = [];
    this.getFilterString();
    this.getSubcategoryList();

  }
  async onCategorySelectAll(event?) {
    Swal.showLoading();
    if (event) {
      this.dashboardForm.controls.subcategory.setValue(null);
      this.dashboardForm.controls.brand.setValue(null);
      this.dashboardForm.controls.sku.setValue(null);
      this.subcategoryIds = [];
      this.brandIds = [];
      this.skus = [];
      this.categoryIds = event.map(category => category.id);
      setTimeout(() => {
        this.getFilterString();
        this.getSubcategoryList();
      }, 500);
    }

    Swal.close();
  }
  async onCategoryDeSelectAll(event) {
    this.dashboardForm.controls.subcategory.setValue(null);
    this.dashboardForm.controls.brand.setValue(null);
    this.dashboardForm.controls.sku.setValue(null);
    this.categoryIds = [];
    this.subcategoryIds = [];
    this.brandIds = [];
    this.skus = [];
    const element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

  }


  // subcategory
  async onSubcategorySelect(event?) {
    Swal.showLoading();
    if (event) {
      this.dashboardForm.controls.brand.setValue(null);
      this.dashboardForm.controls.sku.setValue(null);
      this.brandIds = [];
      this.skus = [];
      this.subcategoryIds.push(event.id);
      this.getFilterString();
      this.getBrandList();
    }
    Swal.close();
  }
  async onSubcategoryDeSelect(event) {
    const idToRemove = this.subcategoryIds.indexOf(event.id);
    if (idToRemove !== -1) {
      this.subcategoryIds.splice(idToRemove, 1);
    }
    this.dashboardForm.controls.brand.setValue(null);
    this.dashboardForm.controls.sku.setValue(null);
    this.brandIds = [];
    this.skus = [];
    this.getFilterString();
    this.getBrandList();
  }
  async onSubcategorySelectAll(event?) {
    Swal.showLoading();
    if (event) {
      this.dashboardForm.controls.brand.setValue(null);
      this.dashboardForm.controls.sku.setValue(null);
      this.brandIds = [];
      this.skus = [];
      this.subcategoryIds = event.map(item => item.id);
      setTimeout(() => {
        this.getFilterString();
        this.getBrandList();
      }, 500);
    }
    Swal.close();
  }
  async onSubcategoryDeSelectAll(event) {
    this.dashboardForm.controls.brand.setValue(null);
    this.dashboardForm.controls.sku.setValue(null);
    this.subcategoryIds = [];
    this.brandIds = [];
    this.skus = [];
    const element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

  }

  // brand

  async onBrandSelect(event?) {
    Swal.showLoading();
    if (event) {
      this.dashboardForm.controls.sku.setValue(null);
      this.skus = [];
      this.brandIds.push(event.id);
      this.getFilterString();
      this.getSkuList();
    }
    Swal.close();
  }
  async onBrandDeSelect(event) {
    const brandIdToRemove = this.brandIds.indexOf(event.id);
    if (brandIdToRemove !== -1) {
      this.brandIds.splice(brandIdToRemove, 1);
    }
    this.dashboardForm.controls.sku.setValue(null);
    this.skus = [];
    this.getFilterString();
    this.getSkuList();

  }
  async onBrandSelectAll(event?) {
    Swal.showLoading();
    if (event) {
      this.dashboardForm.controls.sku.setValue(null);
      this.skus = [];
      this.brandIds = event.map(brand => brand.id);
      setTimeout(() => {
        this.getFilterString();
        this.getSkuList();
      }, 500);
    }

    Swal.close();
  }
  async onBrandDeSelectAll(event) {
    this.dashboardForm.controls.sku.setValue(null);
    this.skus = [];
    this.brandIds = [];
    const element: HTMLElement = document.getElementsByClassName('row')[0] as HTMLElement;
    element.click();

  }

  async onSkuSelect(event?) { this.getFilterString(); }
  async onSkuDeSelect(event) { this.getFilterString(); }
  async onSkuSelectAll(event?) { }
  async onSkuDeSelectAll(event) { }

  customDateFormat(dateToFormat: Moment, format: string) {
    return dateToFormat.format(format);
  }

  getFilterString() {
    const filterDataObj = { ...this.dashboardForm.value };

    this.filter_obj = filterDataObj
    this.filterString = '?';
    for (const filterDataKey in filterDataObj) {

      // if it's date_range, format it
      if (filterDataKey === 'date_range') {
        const dateRange = filterDataObj?.date_range || null;
        if (dateRange) {
          const fromDate: Moment = this.dashboardForm.value?.date_range?.date_after;
          const tillDate: Moment = this.dashboardForm.value?.date_range?.date_before;
          filterDataObj.date_after = this.customDateFormat(fromDate, 'YYYY-MM-DD');
          filterDataObj.date_before = this.customDateFormat(tillDate, 'YYYY-MM-DD');
        }
      }
      delete filterDataObj.date_range;
      const otherFormValues = (filterDataObj[filterDataKey]) as Array<any>;
      if (otherFormValues) {
        filterDataObj[filterDataKey] = otherFormValues.map(eachValue => eachValue.id);

      }
    }

    for (const filter in filterDataObj) {
      if (filter === 'date_after' || filter === 'date_before') {
        this.filterString = this.filterString + `${filter}=${filterDataObj[filter]}&`;
      } else {
        if (filterDataObj[filter]) {
          this.filterString = this.filterString + `${filter}=${filterDataObj[filter].join(',')}&`;
        }
      }
    }
  }

  getRegionList() {
    this.merchOperationService.getBasicRegion()
      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.regions = res.data;
        } else {
          this.regions = [];
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Encountered error while fetching region!',
          timer: 1500
        });
      });
  }
  getGovernorateList() {
    this.merchOperationService.getBasicGovernorate({ region_ids: this.filter_obj.region })

      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.gov = res.data;
        } else {
          this.gov = [];
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Encountered error while fetching governate!',
          timer: 1500
        });
      });
  }

  getCityList() {
    this.merchOperationService.getBasicCity({ governorate_ids: this.filter_obj.governorate })
      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.cities = res.data;
        } else {
          this.gov = [];
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Encountered error while fetching city!',
          timer: 1500
        });
      });
  }
  getStoreList() {

    this.merchOperationService.getBasicStore({
      branch_no_ids: this.filter_obj.branchno,
      city_ids: this.filter_obj.city,
      store_type_ids: this.filter_obj.store_type
    })
      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.stores = res.data;
        } else {
          this.gov = [];
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Encountered error while fetching store!',
          timer: 1500
        });
      });
  }
  getBranchList() {
    this.merchOperationService.getBasicBranch({ city_ids: this.filter_obj.city, store_ids: this.storeIds })
      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.branchs = res.data;
        } else {
          this.gov = [];
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Encountered error while fetching branch!',
          timer: 1500
        });
      });
  }

  getBanchNoList() {
    this.merchOperationService.getBasicBranchNo({ branch_ids: this.filter_obj.branch, city_ids: this.filter_obj.city })
      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.branchNos = res.data;
        } else {
          this.gov = [];
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Encountered error while fetching branch no!',
          timer: 1500
        });
      });
  }

  getStoreTypeList() {

    this.merchOperationService.getBasicStoreType({ branch_no_ids: this.filter_obj.branchno, city_ids: this.filter_obj.city })
      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.storeTypes = res.data;
        } else {
          this.gov = [];
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Encountered error while fetching store types!',
          timer: 1500
        });
      });
  }

  getCategoryList() {
    this.merchOperationService.getBasicCategory(this.filterString)
      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.categories = res.data;
        } else {
          this.gov = [];
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Encountered error while fetching category!',
          timer: 1500
        });
      });
  }

  getSubcategoryList() {
    console.log(this.filter_obj)
    this.merchOperationService.getBasicSubcategory({ category_ids: this.filter_obj.category })
      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.subcategories = res.data;
        } else {
          this.gov = [];
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Encountered error while fetching subcategory!',
          timer: 1500
        });
      });
  }

  getBrandList() {
    this.merchOperationService.getBasicBrand({ subcategory_ids: this.filter_obj.subcategory })
      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.brands = res.data;
        } else {
          this.gov = [];
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Encountered error while fetching brand!',
          timer: 1500
        });
      });
  }
  getSkuList() {
    this.merchOperationService.getBasicSku({ subcategory_ids: this.filter_obj.subcategory, brand_ids: this.filter_obj.brand })
      .subscribe(res => {
        Swal.close();
        if (res && res.success) {
          this.skus = res.data;
        } else {
          this.gov = [];
        }
      }, error => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: error.error_message || 'Encountered error while fetching sku!',
          timer: 1500
        });
      });
  }
}
