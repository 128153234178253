<div class="py-2"
  style="background-color: 'white';margin-bottom: '10px';border-bottom: 10px solid #ebeff2;width: 100% !important;">

  <form action="" [formGroup]="dashboardForm" (ngSubmit)="onFilterSubmit()">
    <div class="row p-3">
      <!-- <div class="col-md-6"> 
    <label class=" col-form-label">Date Range: <span style="color:#ff0000">*</span> </label>
    <input id="daterangepicker" class="form-control" startKey="date_after" endKey="date_before"
       opens="right" drops="down" emptyWeekColumnClass="empty-week-column" ngxDaterangepickerMd (datesUpdated)="datesUpdated($event)"
      [formControl]="dashboardForm.controls['date_range']" [locale]="{'format': 'MM/DD/YYYY'}"
       [alwaysShowCalendars]="true"  [linkedCalendars]="true"
      [showDropdowns]="true" [showRangeLabelOnInput]="showRangeLabelOnInput"
      placeholder="Select the date range..." [class.valid]="dashboardForm.get('date_range').valid &&
      (dashboardForm.get('date_range').dirty || dashboardForm.get('date_range').touched)" [class.invalid]="dashboardForm.get('date_range').invalid &&
      (dashboardForm.get('date_range').dirty || dashboardForm.get('date_range').touched)">
    <div
      *ngIf="dashboardForm.get('date_range').invalid && dashboardForm.get('date_range').errors && (dashboardForm.get('date_range').dirty || dashboardForm.get('date_range').touched)">
      <small class="text-danger" *ngIf="dashboardForm.get('date_range').hasError('required')">
        Date is required. </small>
    </div>
  </div> -->
      <div class="col-md-2">
        <input id="daterangepicker" class="form-control" startKey="date_after" endKey="date_before" opens="right"
          drops="down" emptyWeekColumnClass="empty-week-column" ngxDaterangepickerMd
          (datesUpdated)="datesUpdated($event)" [formControl]="dashboardForm.controls['date_range']"
          [locale]="{'format': 'MM/DD/YYYY'}" [showCustomRangeLabel]="true" [alwaysShowCalendars]="true"
          [ranges]="ranges" [linkedCalendars]="true" [showDropdowns]="true"
          [showRangeLabelOnInput]="showRangeLabelOnInput" placeholder="Select the date range..." />
      </div>


      <!-- Region-->
      <div class=" col-md-2" style="width: 100%">
        <div class="form-group">
          <ng-multiselect-dropdown style="max-width: fit-content;" name="region" id="selRegion"
            [placeholder]="'Select Region'" (onSelect)="onRegionSelect($event)" (onDeSelect)="onRegionDeSelect($event)"
            [settings]="dropdownMultiselectSettings" [data]="regions" [formControl]="dashboardForm.controls['region']"
            (onSelectAll)="onRegionSelectAll($event)" (onDeSelectAll)="onRegionDeSelectAll($event)">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <!-- Gov-->
      <div class=" col-md-2" style="width: 100%">
        <div class="form-group">
          <ng-multiselect-dropdown style="max-width: fit-content;" name="governorate" id="selGovernorate"
            [placeholder]="'Select Governorate'" (onSelect)="onGovernorateSelect($event)"
            (onDeSelect)="onGovernorateDeSelect($event)" [settings]="dropdownMultiselectSettings" [data]="gov"
            [formControl]="dashboardForm.controls['governorate']" (onSelectAll)="onGovernorateSelectAll($event)"
            (onDeSelectAll)="onGovernorateDeSelectAll($event)">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <!-- City-->
      <div class=" col-md-2" style="width: 100%">
        <div class="form-group">
          <ng-multiselect-dropdown style="max-width: fit-content;" name="city" id="selCity"
            [placeholder]="'Select City'" (onSelect)="onCitySelect($event)" (onDeSelect)="onCityDeSelect($event)"
            [settings]="dropdownMultiselectSettings" [data]="cities" [formControl]="dashboardForm.controls['city']"
            (onSelectAll)="onCitySelectAll($event)" (onDeSelectAll)="onCityDeSelectAll($event)">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <!--store type-->
      <div class="col-md-2">
        <div class="form-group">
          <ng-multiselect-dropdown name="channel" id="selStoreType" [placeholder]="'Select store type'"
            [data]="storeTypes" (onSelect)="onStoreTypeSelect($event)" (onDeSelect)="onStoreTypeDeSelect($event)"
            (onSelectAll)="onStoreTypeSelectAll($event)" (onDeSelectAll)="onStoreTypeDeSelectAll($event)"
            [settings]="dropdownMultiselectSettings" [formControl]="dashboardForm.controls['store_type']">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <!-- Store-->
      <div class=" col-md-2" style="width: 100%">
        <div class="form-group">
          <ng-multiselect-dropdown style="max-width: fit-content;" name="store" id="selStore"
            [placeholder]="'Select Store Name'" (onSelect)="onStoreSelect($event)"
            (onDeSelect)="onStoreDeSelect($event)" [settings]="dropdownMultiselectSettings" [data]="stores"
            [formControl]="dashboardForm.controls['store']" (onSelectAll)="onStoreSelectAll($event)"
            (onDeSelectAll)="onStoreDeSelectAll($event)">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <!-- Branch name-->
      <div class=" col-md-2" style="width: 100%">
        <div class="form-group">
          <ng-multiselect-dropdown style="max-width: fit-content;" name="branch_name" id="selBranch"
            [placeholder]="'Select Branch name'" (onSelect)="onBranchSelect($event)"
            (onDeSelect)="onBranchDeSelect($event)" [settings]="dropdownMultiselectSettings" [data]="branchs"
            [formControl]="dashboardForm.controls['branch']" (onSelectAll)="onBranchSelectAll($event)"
            (onDeSelectAll)="onBranchDeSelectAll($event)">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <!-- Branch no-->
      <div class=" col-md-2" style="width: 100%">
        <div class="form-group">
          <ng-multiselect-dropdown style="max-width: fit-content;" name="branch_no" id="selBranch_no"
            [placeholder]="'Select branch number'" (onSelect)="onBranchNoSelect($event)"
            (onDeSelect)="onBranchNoDeSelect($event)" [settings]="dropdownMultiselectSettings" [data]="branchNos"
            [formControl]="dashboardForm.controls['branchno']" (onSelectAll)="onBranchNoSelectAll($event)"
            (onDeSelectAll)="onBranchNoDeSelectAll($event)">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <!-- category-->
      <div class="col-md-2">
        <div class="form-group">
          <ng-multiselect-dropdown name="category" id="selCategory" [placeholder]="'Select Category'"
            [data]="categories" [settings]="dropdownSettings" (onSelect)="onCategorySelect($event)"
            (onSelectAll)="onCategorySelectAll($event)" (onDeSelectAll)="onCategoryDeSelectAll($event)"
            (onDeSelect)="onCategoryDeSelect($event)" [formControl]="dashboardForm.controls['category']">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <!-- sub category-->
      <div class="col-md-2">
        <div class="form-group">
          <ng-multiselect-dropdown name="subcategory" id="selsubCategory" [placeholder]="'Select SubCategory'"
            [data]="subcategories" [settings]="dropdownSettings" (onSelect)="onSubcategorySelect($event)"
            (onSelectAll)="onSubcategorySelectAll($event)" (onDeSelectAll)="onSubcategoryDeSelectAll($event)"
            (onDeSelect)="onSubcategoryDeSelect($event)" [formControl]="dashboardForm.controls['subcategory']">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <!-- brand -->
      <div class="col-md-2">
        <div class="form-group">
          <ng-multiselect-dropdown name="brand" id="selBrand" [placeholder]="'Select Brand'" [data]="brands"
            [settings]="dropdownMultiselectSettings" (onSelect)="onBrandSelect($event)"
            (onDeSelect)="onBrandDeSelect($event)" (onSelectAll)="onBrandSelectAll($event)"
            (onDeSelectAll)="onBrandDeSelectAll($event)" [formControl]="dashboardForm.controls['brand']">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <!--sku-->
      <div class="col-md-2">
        <div class="form-group">
          <ng-multiselect-dropdown name="sku" id="selSKU" [placeholder]="'Select SKU'" [data]="skus"
            (onSelect)="onSkuSelect($event)" (onDeSelect)="onSkuDeSelect($event)" (onSelectAll)="onSkuSelectAll($event)"
            (onDeSelectAll)="onSkuDeSelectAll($event)" [settings]="dropdownMultiselectSettings"
            [formControl]="dashboardForm.controls['sku']">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="d-flex justify-content-between" style="width: 100%;">
        <div class="col-md-2 d-flex align-items-center pt-0">
          <button type="submit" class="btn btn-info mr-2">Apply Filters</button>
          <button type="button" (click)="resetFilters()" class="btn btn-danger">Reset Filters</button>
        </div>

        <div class="col-md-10 scrollbar" style="overflow-x: auto; white-space: nowrap;display: block;text-align: end;max-width: 82%;">
          <button type="button" class="btn btn-success" (click)="scrollToItem(1)"
            style="margin-right: 10px;">Availability</button>

          <button type="button" class="btn btn-success" (click)="scrollToItem(2)" style="margin-right: 10px;">Out of
            Stock</button>

          <button type="button" class="btn btn-success" (click)="scrollToItem(3)" style="margin-right: 10px;">Shelf
            Share</button>

          <button type="button" class="btn btn-success" (click)="scrollToItem(4)" style="margin-right: 10px;">Shelf
            Balance</button>

          <button type="button" class="btn btn-success" (click)="scrollToItem(5)" style="margin-right: 10px;">Secondary
            display</button>

          <button type="button" class="btn btn-success" (click)="scrollToItem(6)" style="margin-right: 10px;">Store
            Balance</button>

          <button type="button" class="btn btn-success" (click)="scrollToItem(7)" style="margin-right: 10px;">Price
            measure</button>

          <button type="button" class="btn btn-success" (click)="scrollToItem(8)"
            style="margin-right: 10px;">Promotions</button>

          <button type="button" (click)="csvreport()" class="btn btn-primary" data-toggle="modal"
            data-target="#csvmodal" href="#">View
            CSV</button>
        </div>
      </div>
    </div>
  </form>

</div>

<div class="modal fade" id="csvmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="max-width:90%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">CSV Table Data</h5>
        <a href="#" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
      </div>
      <div class="modal-body p-4">
        <div class="float-right">


        </div>
        <!-- Self Share Table -->

        <!-- Consolidated Table (SKU)-->

        <table class="custom-table">
          <thead>
            <tr>
              <th>Brand</th>
              <th>Availability</th>
              <th>Out of Stock</th>
              <th>Share of Shelf</th>
              <th>Shelf Balance</th>
              <th>SKU's</th>
              <th>Max</th>
              <th>Min</th>
              <th>Mean</th>
              <th>Mode</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let entry of combinedReportData">
              <td>{{ entry.name }}</td>
              <td>{{ entry.availability ? (entry.availability | number:'1.2-2') : 'N/A' }} %</td>
              <td>{{ entry.out_of_stock ? (entry.out_of_stock | number:'1.2-2') : 'N/A' }} %</td>
              <td>{{ entry.shelf_share ? (entry.shelf_share | number:'1.2-2') : 'N/A' }} %</td>
              <td>{{ entry.shelf_balance ? (entry.shelf_balance | number:'1.2-2') : 'N/A' }} %</td>

              <td style="padding: 0px !important;">
            <tr style="width: 100% !important; display: flex;" *ngFor="let sku of entry.skus">
              <td style="width: 100% !important;">
                {{ sku.sku }}
              </td>
            </tr>
            </td>
            <td style="padding: 0px !important;">
              <tr style="width: 100% !important; display: flex;" *ngFor="let sku of entry.skus">
                <td style="width: 100% !important;">
                  {{ sku.priceData.max ? (sku.priceData.max | number:'1.2-2') : 'N/A' }}
                </td>
              </tr>
            </td>
            <td style="padding: 0px !important;">
              <tr style="width: 100% !important; display: flex;" *ngFor="let sku of entry.skus">
                <td style="width: 100% !important;">
                  {{ sku.priceData.min ? (sku.priceData.min | number:'1.2-2') : 'N/A' }}
                </td>
              </tr>
            </td>
            <td style="padding: 0px !important;">
              <tr style="width: 100% !important; display: flex;" *ngFor="let sku of entry.skus">
                <td style="width: 100% !important;">
                  {{ sku.priceData.mean ? (sku.priceData.mean | number:'1.2-2') : 'N/A' }}
                </td>
              </tr>
            </td>
            <td style="padding: 0px !important;">
              <tr style="width: 100% !important; display: flex;" *ngFor="let sku of entry.skus">
                <td style="width: 100% !important;">
                  {{ sku.priceData.mode ? (sku.priceData.mode | number:'1.2-2') : 'N/A' }}
                </td>
              </tr>
            </td>
          </tbody>
        </table>

        <!-- Consolidated Table
        <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
          <thead>
            <tr>
              <th>Brand</th>
              <th>Availability Value</th>
              <th>Out of Stock Value</th>
              <th>Self Balance Value</th>
              <th>Secondary Display SKU</th>
              <th>Store Balance SKU</th>
              <th>Promotion SKU</th>
              <th>Price Measure SKU</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let entry of merchData">
              <tr>
                <td>{{ entry.selfShare?.data[0]?.brand || entry.availability?.data[0]?.brand ||
                  entry.outOfStock?.data[0]?.brand || '-' }}</td>
                <td>{{ entry.availability?.data[0]?.value || '-' }}</td>
                <td>{{ entry.outOfStock?.data[0]?.value || '-' }}</td>
                <td>{{ entry.selfBalance?.data[0]?.value || '-' }}</td>
                <td>{{ entry.secondaryDisplay?.data[0]?.sku || '-' }}</td>
                <td>{{ entry.storebalance?.data[0]?.sku || '-' }}</td>
                <td>{{ entry.promotion?.data[0]?.sku || '-' }}</td>
                <td>{{ entry.pricemeasure?.data[0]?.sku || '-' }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table> -->


      </div>
      <div class="modal-footer">

        <!-- <div id="export_csv" class="btn btn-lg btn-sm btn-primary rounded-pill py-2 px-4 mr-3 mt-2"
          style="font-size: 0.875rem;margin-bottom: 10px;"
          (click)="exportData('price-monitor','price_monitor_merch.csv')">
          Export CSV
        </div> -->

        <button type="button" class="btn btn-danger" (click)="exportTable()">Export</button>

        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>