import { SnapDashboardService } from 'src/app/_services/snap-dashboard.service';
import { Component, OnInit } from '@angular/core';
import { ExcelData } from 'src/app/dashboard/dashboard.component';
import { OptionQuestion } from 'src/app/dashboard/option-question-chart/option-question-chart.component';
import { DashboardService } from 'src/app/_services/dashboard.service';
import { DataService } from 'src/app/_services/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-merch-option-question-chart',
  templateUrl: './merch-option-question-chart.component.html',
  styleUrls: ['./merch-option-question-chart.component.scss']
})
export class MerchOptionQuestionChartComponent implements OnInit {

  // PieChart - Total answers per country
  height = '120';
  public pieChartDataAll: any = [];
  public optionQuestionPieChartExcelData: ExcelData = { labels: [], data: [] };

  constructor(
    private snapDashboardService: SnapDashboardService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    if (this.dataService.serviceData) {
      this.loadCQLoadOptionQuestions(this.dataService.serviceData);
    } else {
      this.loadCQLoadOptionQuestions();
    }
  }

  loadCQLoadOptionQuestions(filterString?) {
    Swal.showLoading();
    this.snapDashboardService.getCCOptionQuestions(filterString)
      .subscribe(
        response => {
          Swal.close();
          if (response && response.success) {
            const cqOptionQuestions = response.data ? [...response.data] : [];
            this.pieChartDataAll = [...this.formatQuestionOptionsData(cqOptionQuestions)];
            this.optionQuestionPieChartExcelData = { labels: [], data: [] };
          } else {
            this.pieChartDataAll = [];
            this.optionQuestionPieChartExcelData = { labels: [], data: [] };
          }
        },
        error => {
          Swal.close();
          this.pieChartDataAll = [];
          this.optionQuestionPieChartExcelData = { labels: [], data: [] };
          this.handleNotification('Error in fetching total answers per country data!', true);
        }
      );
  }

  formatQuestionOptionsData(dataToFormat: Array<any>): Array<OptionQuestion> {
    const pieChartData: Array<OptionQuestion> = [];
    const uniqueQuestions = [...new Set(dataToFormat.map(item => item.question))];

    uniqueQuestions.forEach(uniqueQuestion => {
      const eachQuestionData: OptionQuestion = {
        question: dataToFormat.find(item => item.question === uniqueQuestion),
        pieChartLabels: [],
        pieChartValues: [],
        excelData: { labels: [], data: [] }
      };

      dataToFormat.forEach(data => {
        if (data.question === uniqueQuestion) {
          eachQuestionData.pieChartLabels.push(data.option);
          eachQuestionData.pieChartValues.push(data.value);
          eachQuestionData.excelData.data.push({ option: data.option, value: data.value });
        }
      });
      eachQuestionData.excelData.labels = ['option', 'value'];
      pieChartData.push({ ...eachQuestionData });
    });

    return pieChartData;
  }

  handleNotification(message: string, isError: boolean) {
    if (!isError) {
      Swal.fire({
        icon: 'success',
        title: message
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message
      });
    }
  }

}
