<!DOCTYPE html>
<html lang="en">

<body>
    <div id="wrapper">
        <!-- Topbar Start -->
        <app-top-bar [pageTitle]="'Operations'" (searchText)="searchText($event)"></app-top-bar>
        <!-- end Topbar -->
    </div>
    <!-- Begin page -->
    <div id="wrapper" style="background-color: '#ebeff2';">



        <!-- ========== Left Sidebar Start ========== -->
        <app-left-side-bar></app-left-side-bar>
        <!-- Left Sidebar End -->

        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="content-page">
            <div class="content">

                <!-- Start Content-->
                <div class="container-fluid">

                    <div class="mb-0">
                        <app-top-title [title]="'Add subcategory to Operations'"
                            [subTitle]="'You can add subCategory to operations from here'"></app-top-title>
                    </div>
                    <div class="bg-white">

                        <form class="" role="form" [formGroup]="subcategoryForm"
                            (ngSubmit)="updating?updatesubcategory():addsubCategory()">
                            <div class="row p-4">

                                <div class="col-md-4" style="width: 100%;">
                                    <div class="form-group">
                                        <label class="col-form-label" for="category"> Category <span
                                                style="color:#ff0000">*</span></label>
                                        <ng-multiselect-dropdown name="category" id="selCategory"
                                            [placeholder]="'Select Category'" [data]="categorys"
                                            [settings]="categoryDropdownSettings"
                                            [formControl]="subcategoryForm.controls['categoryshow']"
                                            [class.valid]="subcategoryForm.get('categoryshow').valid &&
                                                                (subcategoryForm.get('categoryshow').dirty || subcategoryForm.get('categoryshow').touched)"
                                            [class.invalid]="subcategoryForm.get('categoryshow').invalid &&
                                                                (subcategoryForm.get('categoryshow').dirty || subcategoryForm.get('categoryshow').touched)">
                                        </ng-multiselect-dropdown>

                                        <div
                                            *ngIf="subcategoryForm.get('categoryshow').invalid &&
                                                                   subcategoryForm.get('categoryshow').errors &&
                                                                   (subcategoryForm.get('categoryshow').dirty || subcategoryForm.get('categoryshow').touched)">
                                            <small class="text-danger"
                                                *ngIf="subcategoryForm.get('categoryshow').hasError('required')">
                                                Category is required. </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4" style="width: 100%;">
                                    <div class="form-group">
                                        <label class="col-form-label" for="subcategory">subcategory<span
                                                style="color:#ff0000">*</span></label>
                                        <input placeholder="Enter Category Name" type="text" class="form-control"
                                            id="subcategory" formControlName="name" [class.valid]="subcategoryForm.get('name').valid &&
                        (subcategoryForm.get('name').dirty || subcategoryForm.get('name').touched)" [class.invalid]="subcategoryForm.get('name').invalid &&
                        (subcategoryForm.get('name').dirty || subcategoryForm.get('name').touched)">
                                        <div
                                            *ngIf="subcategoryForm.get('name').invalid && subcategoryForm.get('name').errors && (subcategoryForm.get('name').dirty || subcategoryForm.get('name').touched)">
                                            <small class="text-danger"
                                                *ngIf="subcategoryForm.get('name').hasError('required')">
                                                subcategory name is required. </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3" style="width: 100%;">
                                    <div class="form-group">
                                        <label class="col-form-label" for="subcategory">subcategory (Arabic)</label>
                                        <input placeholder="Enter Category Name (Arabic)" type="text" class="form-control"
                                            id="subcategory" formControlName="name_ar" [class.valid]="subcategoryForm.get('name_ar').valid &&
                        (subcategoryForm.get('name_ar').dirty || subcategoryForm.get('name_ar').touched)"
                                            [class.invalid]="subcategoryForm.get('name_ar').invalid &&
                        (subcategoryForm.get('name_ar').dirty || subcategoryForm.get('name_ar').touched)">
                                    </div>
                                </div>
                            </div>

                            <div class="row px-5 pb-3 d-flex">
                                <div class="form-group">
                                    <button type="submit"
                                        class="btn btn-success px-5 mr-2">{{updating?'Update':'Add'}}</button>
                                    <button (click)="toggleUpdateMode()" type="button"
                                        class="btn btn-danger px-5">Cancel</button>
                                </div>
                            </div>

                        </form>
                        <!-- end row -->
                    </div>


                    <div class="mt-3 p-3 bg-white mb-5">
                        <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th>subcategory Id</th>
                                    <th>Category Name</th>
                                    <th>subcategory Name</th>
                                    <th>subcategory Name (Arabic)</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let subcategory of subCategorys">
                                    <td>{{ subcategory.id }}</td>
                                    <td>{{ subcategory.category_name}}</td>
                                    <td>{{ subcategory.name }}</td>
                                    <td>{{ subcategory.name_ar }}</td>
                                    <td>
                                        <a [routerLink]="" (click)="fetchsubcategory(subcategory)"
                                            style="color:'blue';">Edit</a>
                                    </td>
                                    <td>
                                        <a [routerLink]="" (click)="removesubcategory(subcategory.id)"
                                            style="color:'red';"> Delete </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> <!-- container-fluid -->

            </div> <!-- content -->

            <!-- Footer Start -->
            <app-footer></app-footer>
            <!-- end Footer -->

        </div>

        <!-- ============================================================== -->
        <!-- End Page content -->
        <!-- ============================================================== -->


    </div>
    <!-- END wrapper -->

</body>

</html>